<div class="h-full overflow-y-auto p-4" (scroll)="hideColorPicker()">
  <div class="flex items-center">
    <modus-label class="text-xs grow">Classes & Point Styling</modus-label>
    <modus-switch
      id="3d-global-settings-classification-show-toggle"
      class="flex mr-2.5"
      modus-tooltip="Show Classification Colors"
      [formControl]="showClassificationControl"
    ></modus-switch>
  </div>
  <ol class="mt-3">
    @for (item of classificationSchemes(); track item.id) {
      <li
        class="flex items-center text-xs p-y-2 pr-2 mb-2 border-y border-gray-1 border-opacity-40"
      >
        <button
          id="3d-global-settings-classification-item-visible-toggle"
          class="min-w-8"
          modus-icon-button
          (click)="changeVisibility(item)"
        >
          <md-icon class="!text-xl" [ngClass]="{ 'opacity-60': !item.visible }">{{
            item.visible ? 'visibility_on' : 'visibility_off'
          }}</md-icon>
        </button>
        <span class="grow overflow-hidden text-ellipsis whitespace-nowrap pl-1">
          {{ item.name }}
        </span>
        <div class="relative flex items-center ml-2 min-w-9">
          <input
            id="3d-global-settings-classification-color"
            colorPicker="{{ stripAlpha(item.rgba) }}"
            [cpOutputFormat]="'hex'"
            [cpAlphaChannel]="'disabled'"
            [cpPosition]="'top'"
            [cpCancelButton]="true"
            [cpCancelButtonClass]="'modus-button modus-button-medium modus-basic-button'"
            [cpOKButton]="true"
            [cpOKButtonClass]="'modus-button modus-button-medium modus-flat-button-primary'"
            [cpWidth]="'180'"
            [cpUseRootViewContainer]="true"
            (colorPickerChange)="changeColor($event, item)"
            style="background-color: #{{ item.rgba }}"
            class="absolute h-4 w-full border border-gray-0 rounded-2xl cursor-pointer"
            readonly
          />
        </div>
      </li>
    }
  </ol>
</div>
