import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { Subscription, catchError, concatMap, filter, from, map, of, switchMap } from 'rxjs';
import { downloadBlob } from '../../utils/download-blob';
import { ClearAllDownloads, PatchDownloadFile } from './download.actions';
import { DownloadFile, DownloadProgress, DownloadStatus } from './download.model';
import { DownloadState } from './download.state';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private downloaderSubscription$!: Subscription;

  constructor(
    private store: Store,
    private http: HttpClient,
  ) {}

  cancelAllDownloads() {
    this.downloaderSubscription$.unsubscribe();
    this.store.dispatch(new ClearAllDownloads()).subscribe(() => this.subscribeDownloadWatcher());
  }

  subscribeDownloadWatcher() {
    this.downloaderSubscription$ = this.store
      .select(DownloadState.downloads)
      .pipe(
        map((downloads) => {
          const startDownloads = downloads.flatMap((scan) => {
            return scan.files.filter((file) => isNil(file.downloadProgress));
          });

          startDownloads.forEach((file) => {
            file.downloadProgress = {
              status: DownloadStatus.Pending,
              percentageComplete: 0,
              content: null,
            };
          });

          return startDownloads;
        }),
        //run in series
        switchMap((files) => from(files)),
        concatMap((file) => this.downloadFile(file)),
      )
      .subscribe({
        next: (file) => {
          if (file.downloadProgress?.status !== DownloadStatus.Complete) return;

          this.saveFileToClient(file);
        },
      });
  }

  private downloadFile(file: DownloadFile) {
    return this.http
      .get(file.scandataFile.downloadLink, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(
        map((event) => {
          if (isHttpProgressEvent(event)) {
            const percentageComplete = event.total
              ? ((100 * event.loaded) / event.total).toFixed(1)
              : 0;
            return <DownloadProgress>{
              status: DownloadStatus.Busy,
              percentageComplete,
              content: null,
            };
          }

          if (isHttpResponse(event)) {
            return <DownloadProgress>{
              status: DownloadStatus.Complete,
              percentageComplete: 100,
              content: event.body,
            };
          }

          return <DownloadProgress>{
            status: DownloadStatus.Pending,
            percentageComplete: 0,
            content: null,
          };
        }),
        map((progress) => {
          file.downloadProgress = progress;
          this.store.dispatch(new PatchDownloadFile(file));
          return file;
        }),
        filter((file) => file.downloadProgress?.status === DownloadStatus.Complete),
        catchError(() => {
          file.downloadProgress = {
            status: DownloadStatus.Failed,
            percentageComplete: 0,
            content: null,
          };

          this.store.dispatch(new PatchDownloadFile(file));
          return of(file);
        }),
      );
  }

  private saveFileToClient(file: DownloadFile) {
    const progress = file.downloadProgress;

    if (isNil(progress) || isNil(progress.content)) return;

    downloadBlob(progress.content, file.scandataFile.name);
  }
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress
  );
}
