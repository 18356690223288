import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusCheckboxModule,
  ModusIconModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  concatMap,
  debounce,
  forkJoin,
  map,
  of,
  pairwise,
  shareReplay,
  startWith,
  take,
  tap,
  timer,
} from 'rxjs';
import { ScandataFileService } from '../../../../scandata/scandata-file.service';
import { ScandataSelectedState } from '../../../../scandata/scandata-selected.state';
import { PointcloudAPIStatus, ScandataModel } from '../../../../scandata/scandata.models';
import { SetView } from '../../../options-panel.actions';
import { OptionsPanelView } from '../../../options-panel.state';
import { DownloadProgressComponent } from '../../download-progress/download-progress.component';
import { AddToDownload } from '../../download.actions';
import { DownloadFile, DownloadScan } from '../../download.model';
import { DownloadState } from '../../download.state';

interface DownloadAllSelect {
  visible: boolean;
  selected: boolean | 'indeterminate';
  disabled: boolean;
}

interface SelectableFile {
  scandataModelId: string;
  filename: string;
  selected: boolean;
}

interface ScanView extends DownloadScan {
  files: FileView[];
  selected: boolean | 'indeterminate';
  disabled: boolean;
}

interface FileView extends DownloadFile {
  selected: boolean;
  disabled: boolean;
}

@UntilDestroy()
@Component({
  selector: 'sd-download-select-list',
  standalone: true,
  imports: [
    CommonModule,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    ModusCheckboxModule,
    DownloadProgressComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './download-select-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadSelectListComponent implements OnInit {
  @Select(DownloadState.downloads) private currentDownloads$!: Observable<DownloadScan[]>;
  @Select(ScandataSelectedState.chronologicalSelected) private chronologicalSelected$!: Observable<
    ScandataModel[]
  >;

  @Output() cancelClicked = new EventEmitter();
  @Output() loading = new EventEmitter<boolean>();

  pointcloudAPIStatus = PointcloudAPIStatus;

  loading$ = new BehaviorSubject<boolean>(false);
  selectableFiles$ = new BehaviorSubject<SelectableFile[]>([]);
  selectedFiles$ = new BehaviorSubject<SelectableFile[]>([]);

  downloadAllSelect$!: Observable<DownloadAllSelect>;
  downloadDisabled$!: Observable<boolean>;
  selectedScansWithFiles$!: Observable<ScandataModel[]>;
  displayScans$!: Observable<ScanView[]>;

  constructor(
    private store: Store,
    private scandataFileService: ScandataFileService,
  ) {}

  ngOnInit() {
    this.selectedScansWithFiles$ = this.getSelectedScansWithFiles();
    this.subscribeToSelectedScansChange();

    this.displayScans$ = this.getDisplayScans();

    this.downloadAllSelect$ = combineLatest([this.displayScans$, this.loading$]).pipe(
      map(([scans, loading]) => {
        const visible = scans.length > 1;
        const files = scans.flatMap((scan) => scan.files);
        const disabled =
          files.length === 0 ? true : files.every((file) => isDefined(file.downloadProgress));

        const allFilesSelected =
          files.length > 0 && files.every((file) => file.disabled || file.selected);
        const noFilesSelected =
          files.length === 0 || files.every((file) => file.disabled || !file.selected);
        const selected = allFilesSelected ? true : noFilesSelected ? false : 'indeterminate';

        return {
          visible,
          disabled: loading || disabled,
          selected: disabled ? false : selected,
        } satisfies DownloadAllSelect;
      }),
    );

    this.downloadDisabled$ = combineLatest([
      this.displayScans$.pipe(startWith([])),
      this.loading$,
    ]).pipe(
      map(([scans, loading]) => {
        if (loading) return true;

        const files = scans.flatMap((scan) => scan.files);
        return isNil(files.find((file) => file.selected));
      }),
    );

    this.subscribeToSelectedFilesChange();
    this.subscribeToLoadingChange();
  }

  toggleExpanded(scan: ScanView) {
    scan.expanded = !scan.expanded;
  }

  download() {
    this.displayScans$
      .pipe(
        take(1),
        map((scans) => {
          const actions = scans.flatMap((scan) => {
            return scan.files
              .filter((file) => file.selected)
              .map((file) => new AddToDownload(scan, file));
          });

          return this.store.dispatch(actions);
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.store.dispatch(new SetView(OptionsPanelView.DownloadStatus));
      });
  }

  selectAllClicked() {
    const selectableFiles = this.selectableFiles$.value;
    const allSelected = selectableFiles.every((file) => file.selected);
    const selected = allSelected ? false : true;

    selectableFiles.forEach((file) => (file.selected = selected));
    this.selectableFiles$.next(selectableFiles);
  }

  selectScanClicked(scan: ScanView) {
    const selectableFiles = this.selectableFiles$.value;
    const scanFiles = selectableFiles.filter(
      (downloadFile) => downloadFile.scandataModelId === scan.scandataModel.id,
    );

    const allSelected = scanFiles.every((file) => file.selected);
    const selected = allSelected ? false : true;

    scanFiles.forEach((file) => (file.selected = selected));
    this.selectableFiles$.next(selectableFiles);
  }

  selectFileClicked($event: MouseEvent, file: FileView) {
    if ($event.shiftKey) return this.toggleRangeSelected(file);

    this.toggleSingleSelected(file);
  }

  private toggleRangeSelected(file: FileView) {
    const selectedFiles = this.selectedFiles$.value;
    if (selectedFiles.length === 0) return;

    const selectableFiles = this.selectableFiles$.value;
    const lastSelectedFile = selectedFiles.slice(-1)[0];
    const lastSelectedIndex = selectableFiles.findIndex((downloadFile, index) => {
      return downloadFile.scandataModelId === lastSelectedFile.scandataModelId &&
        downloadFile.filename === lastSelectedFile.filename
        ? index
        : null;
    });

    const selectedIndex = selectableFiles.findIndex((downloadFile, index) => {
      return downloadFile.scandataModelId === file.scandataModelId &&
        downloadFile.filename === file.scandataFile.name
        ? index
        : null;
    });

    const startIndex = selectedIndex < lastSelectedIndex ? selectedIndex : lastSelectedIndex;
    const endIndex = selectedIndex > lastSelectedIndex ? selectedIndex : lastSelectedIndex;
    const selection = selectableFiles.filter(
      (_, index) => index >= startIndex && index <= endIndex,
    );
    if (selectedIndex < lastSelectedIndex) selection.reverse();

    const select = !file.selected;
    selection.forEach((scan) => {
      scan.selected = select;
      this.selectableFiles$.next(selectableFiles);
    });
  }

  private toggleSingleSelected(file: FileView) {
    const selectableFiles = this.selectableFiles$.value;
    const selectedFile = selectableFiles.find(
      (downloadFile) =>
        downloadFile.scandataModelId === file.scandataModelId &&
        downloadFile.filename === file.scandataFile.name,
    );

    if (isNil(selectedFile)) return;

    selectedFile.selected = !selectedFile.selected;
    this.selectableFiles$.next(selectableFiles);
  }

  private subscribeToSelectedFilesChange() {
    this.selectableFiles$.pipe(untilDestroyed(this)).subscribe((selectableFiles) => {
      const selectedFiles = this.selectedFiles$.value;

      // filter out no longer selected
      const keepSelection = selectedFiles.filter((selected) =>
        isDefined(
          selectableFiles.find(
            (file) =>
              file.scandataModelId === selected.scandataModelId &&
              file.filename === selected.filename &&
              file.selected,
          ),
        ),
      );

      // add new selected
      const addSelection = selectableFiles.filter((file) => {
        if (!file.selected) return false;

        return isNil(
          keepSelection.find((selected) => {
            return (
              selected.scandataModelId === file.scandataModelId &&
              selected.filename === file.filename
            );
          }),
        );
      });

      this.selectedFiles$.next([...keepSelection, ...addSelection]);
    });
  }

  private subscribeToSelectedScansChange() {
    this.selectedScansWithFiles$.pipe(untilDestroyed(this)).subscribe((scans) => {
      const selectableFiles = this.selectableFiles$.value;
      const newSelectableFiles: SelectableFile[] = scans
        .flatMap((scan) =>
          scan.files?.map((file) => {
            return (
              selectableFiles.find((downloadFile) => {
                return (
                  downloadFile.scandataModelId === scan.id && downloadFile.filename === file.name
                );
              }) ?? {
                scandataModelId: scan.id,
                filename: file.name,
                selected: true,
              }
            );
          }),
        )
        .filter(isDefined);

      this.selectableFiles$.next(newSelectableFiles);
    });
  }

  private subscribeToLoadingChange() {
    this.loading$.pipe(untilDestroyed(this)).subscribe((loading) => this.loading.emit(loading));
  }

  private getIcon(filename: string) {
    const extension = filename.split('.').pop()?.toLowerCase();

    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image';
      default:
        return 'file';
    }
  }

  private getDisplayScans(): Observable<ScanView[]> {
    const selectableFiles$ = this.selectableFiles$.pipe(debounce(() => timer(100)));

    const scans$ = combineLatest([this.getDownloadScans(), selectableFiles$, this.loading$]).pipe(
      map(([scans, selectableFiles, loading]) => {
        return scans.map((scan) => {
          const files: FileView[] = scan.files
            .map((file) => {
              const downloading = isDefined(file.downloadProgress);
              const selected = downloading
                ? false
                : (selectableFiles.find(
                    (downloadFile) =>
                      downloadFile.scandataModelId === scan.scandataModel.id &&
                      downloadFile.filename === file.scandataFile.name,
                  )?.selected ?? true);

              return {
                ...file,
                selected,
                disabled: loading || downloading,
                icon: this.getIcon(file.scandataFile.name),
              };
            })
            .filter(isDefined);

          const selected =
            files.length === 0 || files.every((file) => !file.selected)
              ? false
              : files.every((file) => file.selected || file.disabled)
                ? true
                : 'indeterminate';

          const scanView: ScanView = {
            ...scan,
            files,
            selected,
            disabled:
              loading ||
              files.length === 0 ||
              files.every((file) => isDefined(file.downloadProgress)),
          };

          return scanView;
        });
      }),
    );

    // preserve expand/collapse state
    return scans$.pipe(
      startWith(new Array<ScanView>()),
      pairwise(),
      map(([prev, current]) => {
        current.forEach((scanView) => {
          scanView.expanded =
            prev.find((prevScanView) => prevScanView.scandataModel.id === scanView.scandataModel.id)
              ?.expanded ?? scanView.expanded;
        });

        return current;
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private getSelectedScansWithFiles() {
    return this.chronologicalSelected$.pipe(
      // debounce because select all from the scandata list emits multiple times
      debounce(() => timer(100)),
      concatMap(() => {
        this.loading$.next(true);

        // Because concatMap waits for the observable to complete and projects each source,
        // the source may be stale by the time it get's here.
        // To avoid the stale scans I select them from the store again.
        const loadScans$ = this.store
          .selectSnapshot(ScandataSelectedState.chronologicalSelected)
          .map((scan) => {
            if (isDefined(scan.files)) return of(scan);
            if (scan.status !== PointcloudAPIStatus.Ready) return of(scan);

            return this.scandataFileService.loadScandataFiles(scan);
          });

        return forkJoin(loadScans$);
      }),
      tap(() => this.loading$.next(false)),
      map((scans) => {
        // sort files
        scans.forEach((scan) => {
          scan.files?.sort((a, b) => a.name.localeCompare(b.name));
        });

        return scans;
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private getDownloadScans(): Observable<DownloadScan[]> {
    return combineLatest([this.selectedScansWithFiles$, this.currentDownloads$]).pipe(
      map(([scans, downloads]) => {
        return scans.map((scan) => {
          const downloadScan = downloads.find((download) => download.scandataModel.id === scan.id);
          const files = this.getDownloadFiles(scan, downloadScan);
          const downloadScanView: DownloadScan = {
            scandataModel: scan,
            files,
            expanded: downloadScan?.expanded ?? true,
          };

          return downloadScanView;
        });
      }),
    );
  }

  private getDownloadFiles(scan: ScandataModel, downloadScan?: DownloadScan): DownloadFile[] {
    return (
      scan.files?.map((scandataFile) => {
        return (
          downloadScan?.files.find(
            (downloadFile) => downloadFile.scandataFile.name === scandataFile.name,
          ) ?? {
            scandataModelId: scan.id,
            scandataFile,
            icon: this.getIcon(scandataFile.name),
          }
        );
      }) ?? []
    );
  }
}
