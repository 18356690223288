import { toStackTraceString } from '../../tools/stackTrace/handlingStack';
import { monitor } from '../../tools/monitor';
import { mergeObservables, Observable } from '../../tools/observable';
import { addEventListener } from '../../browser/addEventListener';
import { includes } from '../../tools/utils/polyfills';
import { safeTruncate } from '../../tools/utils/stringUtils';
export var RawReportType = {
  intervention: 'intervention',
  deprecation: 'deprecation',
  cspViolation: 'csp_violation'
};
export function initReportObservable(configuration, apis) {
  var observables = [];
  if (includes(apis, RawReportType.cspViolation)) {
    observables.push(createCspViolationReportObservable(configuration));
  }
  var reportTypes = apis.filter(function (api) {
    return api !== RawReportType.cspViolation;
  });
  if (reportTypes.length) {
    observables.push(createReportObservable(reportTypes));
  }
  return mergeObservables.apply(void 0, observables);
}
function createReportObservable(reportTypes) {
  return new Observable(function (observable) {
    if (!window.ReportingObserver) {
      return;
    }
    var handleReports = monitor(function (reports, _) {
      return reports.forEach(function (report) {
        observable.notify(buildRawReportFromReport(report));
      });
    });
    var observer = new window.ReportingObserver(handleReports, {
      types: reportTypes,
      buffered: true
    });
    observer.observe();
    return function () {
      observer.disconnect();
    };
  });
}
function createCspViolationReportObservable(configuration) {
  return new Observable(function (observable) {
    var stop = addEventListener(configuration, document, "securitypolicyviolation" /* DOM_EVENT.SECURITY_POLICY_VIOLATION */, function (event) {
      observable.notify(buildRawReportFromCspViolation(event));
    }).stop;
    return stop;
  });
}
function buildRawReportFromReport(report) {
  var type = report.type,
    body = report.body;
  return {
    type: type,
    subtype: body.id,
    message: "".concat(type, ": ").concat(body.message),
    originalReport: report,
    stack: buildStack(body.id, body.message, body.sourceFile, body.lineNumber, body.columnNumber)
  };
}
function buildRawReportFromCspViolation(event) {
  var type = RawReportType.cspViolation;
  var message = "'".concat(event.blockedURI, "' blocked by '").concat(event.effectiveDirective, "' directive");
  return {
    type: RawReportType.cspViolation,
    subtype: event.effectiveDirective,
    message: "".concat(type, ": ").concat(message),
    stack: buildStack(event.effectiveDirective, event.originalPolicy ? "".concat(message, " of the policy \"").concat(safeTruncate(event.originalPolicy, 100, '...'), "\"") : 'no policy', event.sourceFile, event.lineNumber, event.columnNumber),
    originalReport: event
  };
}
function buildStack(name, message, sourceFile, lineNumber, columnNumber) {
  return sourceFile ? toStackTraceString({
    name: name,
    message: message,
    stack: [{
      func: '?',
      url: sourceFile,
      line: lineNumber !== null && lineNumber !== void 0 ? lineNumber : undefined,
      column: columnNumber !== null && columnNumber !== void 0 ? columnNumber : undefined
    }]
  }) : undefined;
}
