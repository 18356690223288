import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ModusListModule } from '@trimble-gcs/modus';
import { FileDragDropDirective } from '@trimble-gcs/ngx-common';
import { ALLOWED_EXTENSIONS, createImportFile, ImportFile } from '../import.models';

@Component({
  selector: 'sd-local-file-picker',
  standalone: true,
  imports: [CommonModule, FileDragDropDirective, ReactiveFormsModule, ModusListModule],
  templateUrl: './local-file-picker.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex: 1 1 auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalFilePickerComponent {
  private browse = viewChild.required<ElementRef<HTMLInputElement>>('browse');

  allowedExtensions = ALLOWED_EXTENSIONS;

  disabled = input<boolean>(false);
  filesPicked = output<ImportFile[]>();

  dragOver = signal(false);

  filesDropped(droppedFiles: File[]) {
    const validFiles = droppedFiles.filter((file) => {
      return this.allowedExtensions.some((extension) =>
        file.name.toLowerCase().endsWith(extension.toLowerCase()),
      );
    });

    const importFiles = validFiles.map((file) => createImportFile(file));
    this.filesPicked.emit(importFiles);
  }

  browseClick() {
    if (this.disabled()) return;

    this.browse().nativeElement.click();
  }

  filesSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const fileList = Array.from(target.files ?? []);
    if (fileList.length === 0) return;

    // clear the input, otherwise if the same file is picked the change event won't fire.
    target.value = '';

    const importFiles = fileList.map(createImportFile);
    this.filesPicked.emit(importFiles);
  }
}
