import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { createSelectListFromStringEnum, isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusAutocomplete,
  ModusAutocompleteModule,
  ModusButtonModule,
  ModusDatePickerModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusInputModule,
  ModusSelectModule,
  ModusSwitchModule,
  ModusTooltipModule,
  toIsoDateString,
} from '@trimble-gcs/modus';
import {
  Observable,
  combineLatest,
  combineLatestWith,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import {
  ClearFilters,
  SetFilters,
  UnselectAllScandataModels,
} from '../../scandata/scandata.actions';
import { PointcloudStatus } from '../../scandata/scandata.models';
import { ScandataState } from '../../scandata/scandata.state';
import { UserService } from '../../user/user.service';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ClearError } from '../../error-handling/error.actions';
import { noopErrorObserver } from '../../logging/noop-error-observer';
import { FeatureLayerService } from '../../map/feature-layer/feature-layer.service';
import {
  DEFAULT_FILTER_TAGS_MATCH_ALL_VALUE,
  Filters,
  NO_SCANNER_TYPE_FILTER,
  NO_TAGS_FILTER,
} from '../../scandata/scandata-query.models';
import { ScandataService } from '../../scandata/scandata.service';
import { ScannerTypeService } from '../../scanner-type/scanner-type.service';
import { TagService } from '../../tag/tag.service';
import { User } from '../../user/user.models';
import { NotWhitespaceStringValidator } from '../../utils/not-whitespace-string-validator';
import { SetView } from '../options-panel.actions';
import { OptionsPanelView } from '../options-panel.state';

@UntilDestroy()
@Component({
  selector: 'sd-list-filters',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusAutocompleteModule,
    ModusButtonModule,
    ModusDatePickerModule,
    ModusFormFieldModule,
    ModusIconModule,
    ModusInputModule,
    ModusSelectModule,
    ModusSwitchModule,
    ModusTooltipModule,
    ScrollingModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './list-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListFiltersComponent implements OnInit, AfterViewInit {
  @ViewChild('scannerTypeAutocomplete') scannerTypeAutocomplete!: ModusAutocomplete<string>;
  @ViewChild('tagSelectorAutocomplete') tagSelectorAutocomplete!: ModusAutocomplete<string>;

  @Select(ScandataState.filters) filters$!: Observable<Filters | undefined>;
  @Select(ScandataState.filterCount) filterCount$!: Observable<number | undefined>;

  pointcloudStatusList = createSelectListFromStringEnum(PointcloudStatus);

  isClearDisabled$!: Observable<boolean>;
  isApplyDisabled$!: Observable<boolean>;

  users$!: Observable<User[]>;
  selectedUploadedByLabel$!: Observable<string>;
  filteredTags$!: Observable<string[]>;
  filteredScannerTypes$!: Observable<string[]>;

  maxCaptureDate = toIsoDateString(new Date());

  formGroup = new FormGroup({
    name: new FormControl<string | null>(null, { validators: NotWhitespaceStringValidator }),
    captureFromDate: new FormControl<Date | null>(null),
    captureToDate: new FormControl<Date | null>(null),
    status: new FormControl<PointcloudStatus[] | null>(null),
    scannerType: new FormControl<string | null>(null, { validators: NotWhitespaceStringValidator }),
    tagSelector: new FormControl<string | null>(null),
    tags: new FormControl<string[] | null>(null),
    tagsMatchAll: new FormControl<boolean>(DEFAULT_FILTER_TAGS_MATCH_ALL_VALUE, {
      nonNullable: true,
    }),
    uploadedBy: new FormControl<string[] | null>(null),
    isClassified: new FormControl<boolean | null>(null),
    containsStations: new FormControl<boolean | null>(null),
  });

  get selectedStatusLabel() {
    const selected = this.formGroup.controls.status.value?.map((item) => item.toString());
    return this.getMultiSelectedDisplayValue(selected);
  }

  private tags$!: Observable<string[]>;
  private scannerTypes$!: Observable<string[]>;

  constructor(
    private store: Store,
    private userService: UserService,
    private tagService: TagService,
    private scannerTypeService: ScannerTypeService,
    private scandataService: ScandataService,
    private featureLayerService: FeatureLayerService,
  ) {}

  ngOnInit() {
    this.setupUsers();
    this.setupButtons();
    this.setupFilters();

    this.subscribeToTagsChange();
  }

  ngAfterViewInit() {
    this.setupScannerTypes();
    this.setupTags();
  }

  tagRemoved(tag: string): void {
    const selectedTags = [...(this.formGroup.value.tags ?? [])];
    const index = selectedTags.indexOf(tag);
    if (index >= 0) {
      selectedTags.splice(index, 1);
      this.setSelectedTags(selectedTags);
    }
  }

  tagSelected(tag: string): void {
    const selectedTags = [...(this.formGroup.value.tags ?? [])];
    selectedTags.push(tag);
    this.setSelectedTags(selectedTags);
    this.formGroup.controls.tagSelector.setValue('');
  }

  clearFilters() {
    this.store
      .dispatch([new ClearFilters(), new ClearError('scanLoadError')])
      .pipe(
        switchMap(() => this.clearSelectedScans()),
        switchMap(() => this.scandataService.loadScandata()),
        switchMap(() => this.featureLayerService.loadFeatures()),
      )
      .subscribe(noopErrorObserver);
  }

  applyFilters() {
    this.clearAndCloseTagSelector();

    if (this.formGroup.invalid || this.formGroup.pristine) return;

    const values = this.formGroup.getRawValue();
    const filters: Filters = {
      name: values.name?.trim(),
      status: values.status ?? undefined,
      captureFromDate: values.captureFromDate ?? undefined,
      captureToDate: toEndOfDay(values.captureToDate) ?? undefined,
      scannerType: values.scannerType?.trim(),
      tags: values.tags ?? undefined,
      tagsMatchAll: values.tagsMatchAll,
      uploadedBy: values.uploadedBy ?? undefined,
      isClassified: values.isClassified || undefined,
      containsStations: values.containsStations || undefined,
    };

    this.store
      .dispatch([new SetFilters(filters), new ClearError('scanLoadError')])
      .pipe(
        switchMap(() => this.clearSelectedScans()),
        switchMap(() => this.scandataService.loadScandata()),
        switchMap(() => this.featureLayerService.loadFeatures()),
      )
      .subscribe(noopErrorObserver);
  }

  close() {
    this.store.dispatch(new SetView(OptionsPanelView.None, false));
  }

  private clearSelectedScans() {
    return this.store.selectOnce(ScandataState.selected).pipe(
      switchMap((selected) => {
        return selected.length > 0
          ? this.store.dispatch([
              new UnselectAllScandataModels(),
              new SetView(OptionsPanelView.ListFilters, false),
            ])
          : of(true);
      }),
    );
  }

  private setupUsers() {
    this.users$ = this.getUsers();

    const uploadedBy$ = this.formGroup.controls.uploadedBy.statusChanges.pipe(startWith(null));

    this.selectedUploadedByLabel$ = combineLatest([this.users$, uploadedBy$]).pipe(
      map(([users]) => {
        const selected = this.formGroup.controls.uploadedBy.value
          ?.map((selectedId) => {
            return users.find((user) => user.id === selectedId)?.fullName;
          })
          .filter(isDefined);

        return this.getMultiSelectedDisplayValue(selected);
      }),
    );
  }

  private setupButtons() {
    this.isClearDisabled$ = this.formGroup.valueChanges.pipe(
      startWith(null),
      map(() => {
        if (this.formGroup.dirty) return false;

        return this.store.selectSnapshot(ScandataState.filterCount) === 0;
      }),
    );

    this.isApplyDisabled$ = this.formGroup.valueChanges.pipe(
      startWith(null),
      map(() => this.formGroup.pristine || this.formGroup.invalid),
    );
  }

  private setupFilters() {
    this.filters$.subscribe((filters) => {
      if (isNil(filters)) return;
      this.formGroup.reset({
        name: filters.name ?? null,
        captureFromDate: filters.captureFromDate ?? null,
        captureToDate: filters.captureToDate ?? null,
        status: filters.status ?? null,
        scannerType: filters.scannerType ?? null,
        tagSelector: null,
        tags: filters.tags ?? null,
        tagsMatchAll: filters.tagsMatchAll,
        uploadedBy: filters.uploadedBy ?? null,
        isClassified: filters.isClassified ?? null,
        containsStations: filters.containsStations ?? null,
      });
    });
  }

  private subscribeToTagsChange() {
    this.formGroup.valueChanges.subscribe(() => {
      const tagCount = this.formGroup.value.tags?.length ?? 0;
      const noTagsSelected = this.formGroup.value.tags?.includes(NO_TAGS_FILTER);

      if (tagCount > 1 && noTagsSelected && this.formGroup.value.tagsMatchAll !== false) {
        this.formGroup.controls.tagsMatchAll.setValue(false, { emitEvent: false });
      }

      const disableTagsMatchAll = tagCount < 2 || noTagsSelected;
      disableTagsMatchAll
        ? this.formGroup.controls.tagsMatchAll.disable({ emitEvent: false })
        : this.formGroup.controls.tagsMatchAll.enable({ emitEvent: false });
    });
  }

  private clearAndCloseTagSelector() {
    /**
     * Clear the tag selector filter so user can see it is not forming part of the filters.
     * Ideally this would be done with an event raised by the modus-autocomplete,
     * but modus-autocomplete does not fire the (optionSelected) event if (blur)
     * has been handled.
     * Besides the modus-autocomplete behaviour, (blur) would also be fired if
     * the user uses his mouse to select an option from the expanded list.
     */
    this.formGroup.controls.tagSelector.setValue('');
    this.tagSelectorAutocomplete.closePanel();
  }

  private setupTags() {
    this.tags$ = this.tagService.getTags().pipe(map((tags) => [NO_TAGS_FILTER, ...tags]));

    this.filteredTags$ = this.getFilteredTags();

    this.tagSelectorAutocomplete.inputKeydown$
      .pipe(
        filter((event) => event.key === 'Enter'),
        map(() => {
          const value = this.formGroup.controls.tagSelector.getRawValue();
          if (isNil(value) || value.length === 0) return null;

          const tagExactMatch = this.tagSelectorAutocomplete.optionList.options.find((option) => {
            return option.value.toLowerCase() === value.toLowerCase();
          });
          if (isDefined(tagExactMatch)) return tagExactMatch.value;

          const tagPartialMatch = this.tagSelectorAutocomplete.optionList.options.find((option) => {
            return option.value.toLowerCase().includes(value.toLowerCase());
          });

          return tagPartialMatch?.value ?? null;
        }),
        filter(isDefined),
        untilDestroyed(this),
      )
      .subscribe((tag) => {
        this.tagSelected(tag);
        this.clearAndCloseTagSelector();
      });
  }

  private getFilteredTags() {
    return this.formGroup.controls.tagSelector.valueChanges.pipe(
      startWith(null),
      combineLatestWith(this.tags$),
      map(([filter, tags]) => {
        const selectedTags = this.formGroup.value.tags ?? [];
        const availableTags = tags.filter((tag) => !selectedTags.includes(tag));

        if (isNil(filter)) return availableTags;

        const filterValue = filter.toLowerCase();
        return availableTags.filter((tag) => tag.toLowerCase().includes(filterValue));
      }),
    );
  }

  private setupScannerTypes() {
    this.scannerTypes$ = this.scannerTypeService
      .getScannerTypes()
      .pipe(map((scannerTypes) => [NO_SCANNER_TYPE_FILTER, ...scannerTypes]));

    this.filteredScannerTypes$ = this.getFilteredScannerTypes();

    this.scannerTypeAutocomplete.inputKeydown$
      .pipe(
        filter((event) => event.key === 'Enter'),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.scannerTypeAutocomplete.closePanel();
        this.applyFilters();
      });
  }

  private getFilteredScannerTypes() {
    return this.formGroup.controls.scannerType.valueChanges.pipe(
      startWith(null),
      combineLatestWith(this.scannerTypes$),
      map(([filter, scannerTypes]) => {
        if (isNil(filter)) return scannerTypes;

        const filterValue = filter.toLowerCase();
        return scannerTypes.filter((tag) => tag.toLowerCase().includes(filterValue));
      }),
    );
  }

  private getUsers() {
    return this.userService.getUsers().pipe(
      map((users) => users.sort((a, b) => a.fullName.localeCompare(b.fullName))),
      shareReplay({ refCount: true, bufferSize: 1 }),
      untilDestroyed(this),
    );
  }

  private setSelectedTags(selectedTags: string[]) {
    this.formGroup.markAsDirty();
    this.formGroup.controls.tags.setValue(selectedTags);
    this.formGroup.controls.tagSelector.setValue(null);
  }

  private getMultiSelectedDisplayValue(selected?: string[]) {
    if (isNil(selected) || selected.length === 0) return '';

    const label = selected[0];
    const summary =
      selected.length > 1
        ? `(+ ${selected.length - 1} ${selected.length === 2 ? 'other' : 'others'})`
        : '';

    return `${label} ${summary}`.trim();
  }
}

function toEndOfDay(date?: Date | null) {
  if (isNil(date)) return null;

  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
}
