<table mat-table [dataSource]="dataSource" matSort>
  <!-- id column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="id" class="w-[90px] whitespace-nowrap">
      Class #
    </th>
    <td mat-cell *matCellDef="let row">
      @if (row.value.isNew) {
        <input
          modus-input
          type="number"
          [min]="minClassificationId"
          [max]="maxClassificationId"
          [formControl]="row.controls.id"
          (blur)="validateUniqueIds()"
          placeholder="Class #"
        />
      } @else {
        {{ row.value.id }}
      }
    </td>
    <td mat-footer-cell *matFooterCellDef>
      @if (showAdd) {
        <button
          class="text-gray-8"
          modus-icon-button
          modus-tooltip="Add Classification"
          [disabled]="form.disabled"
          (click)="addNewClassifcation()"
        >
          <md-icon>add</md-icon>
        </button>
      }
    </td>
  </ng-container>

  <!-- name column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Description</th>
    <td mat-cell *matCellDef="let row">
      <input
        modus-input
        [formControl]="row.controls.name"
        (blur)="trimInputValue(row.controls.name)"
        placeholder="Add New Classification"
      />
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <!-- rgba column -->
  <ng-container matColumnDef="rgba">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="rgba" class="w-[120px]">Point Style</th>
    <td mat-cell *matCellDef="let row" class="!overflow-visible">
      <span class="relative z-0 flex justify-center items-center mr-4">
        <input
          [formControl]="row.controls.rgba"
          colorPicker="#{{ row.value.rgba }}"
          [cpOutputFormat]="'hex'"
          [cpAlphaChannel]="'disabled'"
          [cpPosition]="'bottom-left'"
          [cpCancelButton]="true"
          [cpCancelButtonClass]="'modus-button modus-button-medium modus-basic-button'"
          [cpOKButton]="true"
          [cpOKButtonClass]="'modus-button modus-button-medium modus-flat-button-primary'"
          (colorPickerSelect)="setColor($event, row)"
          style="background-color: #{{ row.value.rgba }}"
          class="absolute w-10 pl-10 border border-gray-0 rounded-2xl"
          [ngClass]="{ 'cursor-pointer': row.enabled }"
          readonly
        />
      </span>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <!-- visible column -->
  <ng-container matColumnDef="visible">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="visible" class="w-[120px]">
      Default 3D Visibility
    </th>
    <td mat-cell *matCellDef="let row" class="!text-center">
      <button modus-icon-button (click)="toggleVisibility(row)" [disabled]="row.disabled">
        @if (row.value.visible) {
          <md-icon>visibility_on</md-icon>
        } @else {
          <md-icon>visibility_off</md-icon>
        }
      </button>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <!-- delete column -->
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef class="w-[80px]">Delete</th>
    <td mat-cell *matCellDef="let row" class="!text-center">
      @if (!row.value.predefined) {
        <button modus-icon-button (click)="deleteClassification(row)" [disabled]="row.disabled">
          <md-icon>delete</md-icon>
        </button>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
