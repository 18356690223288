import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { FitToView } from '../connect-3d-ext/host-3d.actions';
import { ProjectQuotaService } from '../quota/project-quota.service';
import { PatchScandataModel, PatchScandataModels } from '../scandata/scandata.actions';
import { ScandataModel } from '../scandata/scandata.models';
import { ScandataState } from '../scandata/scandata.state';
import { ClearCurrentStation, SetCurrentStation } from '../station/station.actions';
import { Station, StationDisplayStatus } from '../station/station.models';
import { StationState } from '../station/station.state';
import { Scan3dStyle } from './models/scan-3d-style';
import { Scan3dListComponent } from './scan-3d-list/scan-3d-list.component';
import { Scan3dOptionsPanelComponent } from './scan-3d-options-panel/scan-3d-options-panel.component';
import { Scan3dOptionsPanelView } from './scan-3d-options-panel/scan-3d-options-panel.models';
import { SetGlobalStyle } from './scan-3d.actions';
import { Scan3dService } from './scan-3d.service';
import { Scan3dState } from './scan-3d.state';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-panel',
  standalone: true,
  imports: [CommonModule, Scan3dListComponent, Scan3dOptionsPanelComponent],
  templateUrl: './scan-3d-panel.component.html',
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dPanelComponent {
  optionsPanelView = signal<Scan3dOptionsPanelView>(Scan3dOptionsPanelView.None);
  optionsPanelExpanded = computed(() => this.optionsPanelView() !== Scan3dOptionsPanelView.None);

  private expand = signal(true);
  private selectedScanId = signal<string | undefined>(undefined);

  selectedScan = computed(() => {
    return this.store
      .selectSignal(ScandataState.scandata)()
      .find((scan) => scan.id === this.selectedScanId());
  });

  //selectedScan3dStyle = signal<Scan3dStyle | undefined>(undefined);

  selectedScan3dStyle = computed(() => {
    const selectedScan = this.selectedScan();
    if (isNil(selectedScan)) return;

    return selectedScan.scan3dStyle ?? this.getCopyOfStyle(this.globalStyle());
  });

  globalStyle = toSignal(this.scan3dService.getGlobalStyle());

  quotaExceeded = toSignal(this.projectQuotaService.quotaExceeded(), { initialValue: false });

  stationView = computed(() => this.optionsPanelView() === Scan3dOptionsPanelView.Station);

  selectedStation = computed(() => {
    return this.store.selectSignal(StationState.currentStation)()?.station;
  });

  constructor(
    private store: Store,
    private scan3dService: Scan3dService,
    private projectQuotaService: ProjectQuotaService,
  ) {}

  onExitStationClick() {
    this.optionsPanelView.set(Scan3dOptionsPanelView.None);
    this.store.dispatch(new ClearCurrentStation());
  }

  onSettingsClick() {
    const currentView = this.optionsPanelView();

    if (currentView !== Scan3dOptionsPanelView.Settings) {
      this.optionsPanelView.set(Scan3dOptionsPanelView.Settings);
      return;
    }

    if (isDefined(this.selectedScan()))
      return this.optionsPanelView.set(Scan3dOptionsPanelView.Scan);

    if (isDefined(this.selectedStation()))
      return this.optionsPanelView.set(Scan3dOptionsPanelView.Station);

    this.optionsPanelView.set(Scan3dOptionsPanelView.None);
  }

  onFitToViewClick(scan: ScandataModel) {
    this.store.dispatch(new FitToView([scan]));
  }

  onScanSelected(scan: ScandataModel | undefined) {
    this.selectedScanId.set(scan?.id);

    if (isDefined(scan)) {
      this.optionsPanelView.set(Scan3dOptionsPanelView.Scan);
    } else if (
      isDefined(this.selectedStation()) &&
      this.optionsPanelView() !== Scan3dOptionsPanelView.None
    ) {
      this.optionsPanelView.set(Scan3dOptionsPanelView.Station);
    } else {
      this.optionsPanelView.set(Scan3dOptionsPanelView.None);
    }
  }

  onStationSelected(station: Station | undefined) {
    if (isDefined(station)) {
      this.optionsPanelView.set(Scan3dOptionsPanelView.Station);
    } else if (
      isDefined(this.selectedScan()) &&
      this.optionsPanelView() !== Scan3dOptionsPanelView.None
    ) {
      this.optionsPanelView.set(Scan3dOptionsPanelView.Scan);
    } else {
      this.optionsPanelView.set(Scan3dOptionsPanelView.None);
    }
  }

  onSelectStationClick(station: Station) {
    this.store.dispatch(
      new SetCurrentStation({
        station,
        displayStatus: StationDisplayStatus.AwaitingDisplay,
      }),
    );
  }

  onToggleExpandClick(view: Scan3dOptionsPanelView) {
    const currentView = this.optionsPanelView();
    if (currentView === view) {
      this.expand.set(false);
      this.optionsPanelView.set(Scan3dOptionsPanelView.None);
    } else {
      this.expand.set(true);
      this.optionsPanelView.set(view);
    }
  }

  onGlobalStyleChange(style: Scan3dStyle) {
    // Turn eye dome lighting on when classification color is switched on
    const globalStyle = this.store.selectSnapshot(Scan3dState.globalStyle);
    const classificationShowing = globalStyle?.showClassification === true;
    if (
      classificationShowing === false &&
      style.showClassification === true &&
      style.showEyeDomeLighting === false
    ) {
      style.showEyeDomeLighting = true;
    }

    const scans = this.store
      .selectSnapshot(ScandataState.scandata)
      .filter((scan) => isDefined(scan.scan3dStyle))
      .map((scan) => {
        return { ...scan, scan3dStyle: undefined };
      });

    this.store.dispatch([new SetGlobalStyle(style), new PatchScandataModels(scans)]);
  }

  onStyleChange(style: Scan3dStyle) {
    const scan = this.selectedScan();
    const scan3dStyle = this.selectedScan3dStyle();
    if (isNil(scan) || isNil(scan3dStyle)) return;

    // Turn eye dome lighting on when classification color is switched on
    const classificationShowing = scan3dStyle.showClassification === true;

    if (
      classificationShowing === false &&
      style.showClassification === true &&
      style.showEyeDomeLighting === false
    ) {
      style.showEyeDomeLighting = true;
    }

    scan.scan3dStyle = style;

    this.store.dispatch(
      new PatchScandataModel({
        id: scan.id,
        scan3dStyle: scan.scan3dStyle,
      }),
    );
  }

  private getCopyOfStyle(style?: Scan3dStyle): Scan3dStyle | undefined {
    return isDefined(style)
      ? { ...style, classificationSchemes: [...style.classificationSchemes].map((x) => ({ ...x })) }
      : undefined;
  }
}
