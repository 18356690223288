<div #scrollContainer class="h-full pb-3 pt-1 overflow-y-auto">
  @for (item of treeData(); track item.scan.id) {
    <div>
      <div
        class="flex flex-row min-h-[32px] text-sm cursor-pointer hover:bg-gray-0"
        [ngClass]="{
          'bg-blue-pale font-semibold trimble border-l-[0.2rem] text-trimble-blue-dark border-l-blue-light':
            item.selected,
          'hover:bg-gray-0 pl-[0.2rem]': !item.selected,
        }"
      >
        @if (item.scan.id === lastSelectedId()) {
          <div #activeElement class="min-h-[32px]"></div>
        }

        <sd-scandata-tree-icon
          class="flex items-center cursor-default min-w-[28px]"
          [treeIcon]="getModelIcon(item.scan)"
          [disabled]="loadModelDisabled(item.scan)"
          (clicked)="modelIconClicked(item.scan)"
        ></sd-scandata-tree-icon>

        <div
          modus-tooltip="{{ item.scan.name }}"
          class="flex items-center w-full overflow-hidden p-1"
          (click)="modelNameClicked(item.scan)"
        >
          <div
            class="block w-full overflow-hidden text-ellipsis whitespace-nowrap"
            [ngClass]="{
              'text-red': modelHasError(item.scan),
              'text-gray-6': modelNotReady(item.scan),
            }"
          >
            {{ item.scan.name }}
            <div class="text-small italic text-gray-6">{{ getModelInfoMessage(item.scan) }}</div>
          </div>
          @if (item.version) {
            <modus-badge class="ml-2 w-10 text-center" type="counter" color="tertiary">{{
              item.version
            }}</modus-badge>
          }
        </div>

        @if (item.scan.numberOfStations > 0) {
          <button
            modus-icon-button
            class="text-gray-8 rounded-2xl min-w-8"
            (click)="toggleExpand(item.scan)"
          >
            <md-icon class="!flex text-lg/5 h-full items-center">{{
              item.scan.expanded ? 'caret_down' : 'caret_right'
            }}</md-icon>
          </button>
        } @else {
          <span class="min-w-8" (click)="modelNameClicked(item.scan)"></span>
        }
      </div>
      @if (item.scan.numberOfStations > 0 && item.scan.expanded) {
        <div class="cursor-pointer">
          @if (!item.scan.stations && stationsLoading(item.scan)) {
            <div class="text-small italic py-1 pl-6">Loading...</div>
          }
          @for (station of item.scan.stations; track station.id) {
            <div
              class="flex flex-row min-h-[32px] pl-4 items-center text-sm hover:bg-gray-0"
              [ngClass]="{
                'bg-blue-pale font-semibold border-l-[0.2rem] text-trimble-blue-dark border-l-blue-light':
                  station.id === selectedStation()?.id,
                'hover:bg-gray-0 pl-[1.2rem]': station.id !== selectedStation()?.id,
              }"
            >
              @if (station.id === lastSelectedId()) {
                <div #activeElement class="min-h-[32px]"></div>
              }

              <div
                modus-tooltip="{{ stationDisabledReason() }}"
                tooltipDisabled="{{ stationTooltipDisabled() }}"
                class="flex justify-center items-center min-w-[28px] max-w-[28px] h-full"
              >
                @let treeIcon = getStationIcon(station);
                @if (treeIcon === treeIconType.Loading) {
                  <div class="flex justify-center items-center w-full h-full">
                    <mat-spinner [diameter]="18" [strokeWidth]="2"></mat-spinner>
                  </div>
                } @else {
                  @let disabled = loadStationDisabled();
                  <div
                    class="flex justify-center items-center cursor-pointer min-w-[1.75rem] h-full text-gray-8"
                    (click)="stationIconClicked(station)"
                    [ngClass]="{ 'cursor-default': disabled }"
                  >
                    @switch (treeIcon) {
                      @case (treeIconType.On) {
                        <i
                          class="flex justify-center items-center text-xl tc-icon-eye-visibility"
                          [ngClass]="{ 'opacity-30': disabled }"
                        ></i>
                      }
                      @case (treeIconType.Off) {
                        <i
                          class="flex justify-center items-center text-xl tc-icon-eye-visibility-off-download"
                          [ngClass]="{ 'opacity-30': disabled }"
                        ></i>
                      }
                      @case (treeIconType.Unavailable) {
                        <i
                          class="flex justify-center items-center text-xl tc-icon-eye-visibility-off-error"
                          [ngClass]="{ 'opacity-30': disabled }"
                        ></i>
                      }
                      @case (treeIconType.Error) {
                        <i
                          class="flex justify-center items-center text-xl tc-icon-eye-visibility-off-error text-red"
                        ></i>
                      }
                    }
                  </div>
                }
              </div>
              <div
                modus-tooltip="{{ 'Station: ' + station.name }}"
                class="w-full overflow-hidden pl-1 py-1 pr-8"
                (click)="stationNameClicked(station)"
              >
                <div
                  class="w-full overflow-hidden text-ellipsis whitespace-nowrap"
                  [ngClass]="{ 'text-red': stationHasError(station) }"
                >
                  {{ 'Station: ' + station.name }}
                </div>
                <div
                  class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-small italic"
                >
                  {{ getStationErrorMessage(station) }}
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>
  }
</div>
