<mat-toolbar>
  <nav
    class="bg-gray-0 rounded-[4px]"
    mat-tab-nav-bar
    [tabPanel]="tabPanel"
    color="primary"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
  >
    @for (link of links; track link.title) {
      <a
        id="menu-bar-tab-{{ link.title.toLowerCase() }}"
        mat-tab-link
        class="hover:!bg-tertiary"
        [modus-tooltip]="link.tooltip"
        [active]="activeRoute() === link.route"
        (click)="navigateClick(link.route)"
        disableRipple
      >
        <md-icon>{{ link.icon }}</md-icon>
      </a>
    }
  </nav>

  <button
    id="menu-bar-view-in-3d"
    modus-icon-button
    class="ml-4 bg-gray-0 rounded-[4px] text-base font-bold"
    modus-tooltip="3D View"
    (click)="view3DClick()"
  >
    3D
  </button>

  <div class="ml-4 w-1/2 max-w-72">
    <!-- search -->
    <modus-form-field>
      <md-icon modus-icon-prefix>search</md-icon>
      <input modus-input placeholder="Find" [formControl]="textFilterControl" />
      @if (showTextFilterClear()) {
        <button modus-icon-suffix (click)="clearTextFilterClick()">
          <md-icon>close</md-icon>
        </button>
      }
    </modus-form-field>
  </div>

  <div class="flex grow h-12 w-1/2 justify-end">
    <div class="flex items-center">
      <button
        id="menu-bar-filters"
        modus-icon-button
        class="mx-1"
        modus-tooltip="Filter"
        (click)="toggleOptionsPanelViewClick(panelView.ListFilters)"
        [ngClass]="{ 'text-trimble-blue bg-gray-1': filterPanelActive() }"
      >
        <md-icon>filter</md-icon>

        @if (filterCount() > 0) {
          <modus-badge class="absolute -top-[14px] -right-[12px]" type="counter">{{
            filterCount()
          }}</modus-badge>
        }
      </button>

      <button
        id="menu-bar-downloads"
        modus-icon-button
        class="mx-1"
        modus-tooltip="Download Status"
        (click)="toggleOptionsPanelViewClick(panelView.DownloadStatus)"
        [ngClass]="{ 'text-trimble-blue bg-gray-1': downloadStatusPanelActive() }"
      >
        <md-icon>download_line</md-icon>

        @if (downloadCount() > 0) {
          <modus-badge class="absolute -top-[14px] -right-[12px]" type="counter">{{
            downloadCount()
          }}</modus-badge>
        }
      </button>

      <span class="mx-2 h-8 border-r border-gray-4"></span>

      <button
        id="menu-bar-refresh"
        modus-icon-button
        class="mx-1"
        modus-tooltip="Reload Project Data"
        (click)="refreshClick()"
      >
        <md-icon>refresh</md-icon>
      </button>

      @if (showSettings()) {
        <button
          id="menu-bar-settings"
          modus-icon-button
          class="mx-2"
          modus-tooltip="Settings"
          (click)="viewSettingsClick()"
        >
          <md-icon>settings</md-icon>
        </button>
      }

      @if (showImport()) {
        <button
          id="menu-bar-import"
          color="primary"
          modus-flat-button
          class="ml-3"
          (click)="importClick()"
        >
          Upload
          @if (importCount() > 0) {
            <modus-badge class="absolute -top-[10px] -right-[12px]" type="counter">{{
              importCount()
            }}</modus-badge>
          } @else if (importError()) {
            <div class="absolute -top-[10px] -right-[12px] w-5 rounded-2xl bg-red text-white">
              !
            </div>
          }
        </button>
      }
    </div>
  </div>
</mat-toolbar>

<mat-tab-nav-panel #tabPanel class="flex-1 overflow-hidden border-t border-t-gray-1">
  <mat-drawer-container class="h-full">
    <mat-drawer [opened]="showOptionsPanel()" mode="side" position="end" disableClose="true">
      <sd-options-panel></sd-options-panel>
    </mat-drawer>
    <mat-drawer-content>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-tab-nav-panel>
