<div class="flex flex-auto flex-col">
  <div
    file-drag-drop
    class="flex flex-auto border-2 border-dashed text-gray-6 bg-gray-light border-gray-6"
    (filesDropped)="filesDropped($event)"
    (dragOver)="dragOver.set($event)"
    [disabled]="disabled()"
    [ngClass]="{ '!bg-blue-pale !border-trimble-blue !text-trimble-blue': dragOver() }"
  >
    <div
      class="flex flex-auto flex-col p-2 items-center justify-center pointer-events-none select-none"
    >
      <md-icon class="!text-5xl">cloud_upload</md-icon>
      <div style="white-space-collapse: preserve">
        Drag .las, .laz or .e57 files here or
        <a
          class="font-semibold pointer-events-auto"
          (click)="browseClick()"
          [ngClass]="{ 'cursor-pointer text-trimble-blue': !disabled() }"
          >browse</a
        >
        to upload.
      </div>
    </div>
  </div>

  <input
    #browse
    class="hidden"
    id="file-input-element"
    type="file"
    accept="{{ allowedExtensions }}"
    multiple
    (change)="filesSelected($event)"
  />
</div>
