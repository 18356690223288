import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  Observable,
  Subject,
  catchError,
  forkJoin,
  map,
  of,
  retry,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DialogData } from '../dialog/dialog.model';
import { DialogService } from '../dialog/dialog.service';
import { HANDLE_ERROR } from '../error-handling/error-handler-operator';
import { ClearError } from '../error-handling/error.actions';
import { LoadingService } from '../loading/loading.service';
import { PageData } from '../shared/page-data.model';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import { ScandataFilterService } from './scandata-filter.service';
import {
  AddScan,
  ClearScandata,
  PatchScandataModel,
  PatchScandataModels,
  RemoveScandataModel,
  SetIsLoading,
  SetScandata,
  UpdateScandata,
} from './scandata.actions';
import {
  PointcloudStatus,
  PointcloudStatusMap,
  ScandataDisplayStatus,
  ScandataModel,
  UpdateScandataModel,
} from './scandata.models';
import { ScandataState } from './scandata.state';

@Injectable({
  providedIn: 'root',
})
export class ScandataService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);
  private readonly handleError = inject(HANDLE_ERROR);

  private readonly cancelGetScandata = new Subject<void>();

  constructor(
    private store: Store,
    private http: HttpClient,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private scandataFilterService: ScandataFilterService,
  ) {
    this.loadingService
      .isLoading$(this)
      .pipe(tap((loading) => this.store.dispatch(new SetIsLoading(loading))))
      .subscribe();
  }

  setDisplayStatus(scandata: ScandataModel[], displayStatus: ScandataDisplayStatus) {
    if (scandata.length === 0) {
      return;
    }

    const patchModels: Partial<ScandataModel>[] = scandata.map((item) => ({
      id: item.id,
      displayStatus,
    }));

    this.store.dispatch(new PatchScandataModels(patchModels));
  }

  loadScandata() {
    return this.getScandata().pipe(
      switchMap((data) => {
        /**
         * This could complete after scans have been set in the store by the 3D host,
         * therefore it dispatches UpdateScandata, and not SetScandata.
         */
        return this.store.dispatch(new UpdateScandata(data));
      }),
    );
  }

  refreshScandata() {
    return this.getScandata().pipe(
      switchMap((data) => {
        return this.store.dispatch(new SetScandata(data));
      }),
    );
  }

  getScandataModel(pointcloudId: string): Observable<ScandataModel> {
    return this.store.selectOnce(ScandataState.scandata).pipe(
      map((models) => {
        return models.find((model) => model.id == pointcloudId);
      }),
      switchMap((model) =>
        isDefined(model)
          ? of(model)
          : this.getAndCacheScandataModel(pointcloudId).pipe(
              this.handleError('scanDetailsLoadError'),
            ),
      ),
    );
  }

  getScanByExternalFileId(externalFileId: string) {
    return this.getScanProjectUrl$(`/pointclouds?filterBy=externalFileId=${externalFileId}`).pipe(
      switchMap((url) => this.http.get<PageData<ScandataModel>>(url)),
      map((pageData) => pageData.items.at(0)),
      map((scan) => (scan ? this.mapScandataModel(scan) : undefined)),
      switchMap((scan) => (isDefined(scan) ? this.cacheScanIfMatchesFilters(scan) : of(undefined))),
    );
  }

  updateScandataModel(
    pointcloudId: string,
    updateScandataModel: UpdateScandataModel,
  ): Observable<ScandataModel> {
    return this.getScanProjectUrl$(`/pointclouds/${pointcloudId}`).pipe(
      switchMap((url) => {
        return this.http.patch<ScandataModel>(url, updateScandataModel);
      }),
      map((updatedModel) => this.mapScandataModel(updatedModel)),
      switchMap((updatedModel) => {
        const patchModel = {
          ...updatedModel,
          ...{
            captureDatetimeUtc: updatedModel.captureDatetimeUtc,
            scannerType: updatedModel.scannerType,
            notes: updatedModel.notes,
          },
        };

        return this.store
          .dispatch(new PatchScandataModel(patchModel))
          .pipe(map(() => updatedModel));
      }),
      this.handleError('scanDetailsSaveError'),
    );
  }

  updateScandataModels(updateScandataModels: (UpdateScandataModel & { pointcloudId: string })[]) {
    const updateList = updateScandataModels.map((model) => {
      const dto = {
        updateScandataModel: model,
        responseScandataModel: <ScandataModel | null>null,
        error: <string | null>null,
      };

      return this.getScanProjectUrl$(`/pointclouds/${dto.updateScandataModel.pointcloudId}`).pipe(
        switchMap((url) => {
          return this.http.patch<ScandataModel>(url, dto.updateScandataModel);
        }),
        map((response) => {
          dto.responseScandataModel = response;
          return dto;
        }),
        catchError((error) => {
          dto.error = error;
          return of(dto);
        }),
      );
    });

    return forkJoin(updateList).pipe(
      switchMap((result) => {
        const patchActions = result
          .filter((item) => isNil(item.error) && isDefined(item.responseScandataModel))
          .map((item) => item.responseScandataModel as ScandataModel)
          .map((updatedModel) => this.mapScandataModel(updatedModel))
          .map((updatedModel) => {
            const patchModel = {
              ...updatedModel,
              ...{
                captureDatetimeUtc: updatedModel.captureDatetimeUtc,
                scannerType: updatedModel.scannerType,
                notes: updatedModel.notes,
              },
            };

            return new PatchScandataModel(patchModel);
          });

        return this.store.dispatch(patchActions).pipe(map(() => result));
      }),
    );
  }

  deleteScandataModel(pointcloudId: string) {
    return this.getScanProjectUrl$(`/pointclouds/${pointcloudId}`).pipe(
      switchMap((url) => {
        return this.http.delete<void>(url);
      }),
      switchMap(() => {
        return this.store.dispatch(new RemoveScandataModel(pointcloudId));
      }),
      map(() => pointcloudId),
    );
  }

  deleteScandataModels(scandataModels: ScandataModel[]) {
    const deleteList = scandataModels.map((model) => {
      const dto = { scandataModel: model, error: null };

      return this.getScanProjectUrl$(`/pointclouds/${dto.scandataModel.id}`).pipe(
        switchMap((url) => {
          return this.http.delete<void>(url);
        }),
        map(() => dto),
        catchError((error) => {
          dto.error = error;
          return of(dto);
        }),
      );
    });

    return forkJoin(deleteList).pipe(
      switchMap((result) => {
        const removeActions = result
          .filter((item) => isNil(item.error))
          .map((item) => new RemoveScandataModel(item.scandataModel.id));

        return this.store.dispatch(removeActions).pipe(map(() => result));
      }),
    );
  }

  private getScandata(): Observable<ScandataModel[]> {
    this.cancelGetScandata.next();

    return this.scandataFilterService.getQuerystring().pipe(
      switchMap((querystring) => this.getScanProjectUrl$(`/pointclouds?${querystring}`)),
      switchMap((url) => {
        const request = this.http.get<PageData<ScandataModel>>(url);
        return this.loadingService.loadFrom(request, this);
      }),
      map((pageData) => pageData.items),
      this.handleError('scanLoadError'),
      tap({ error: () => this.store.dispatch(ClearScandata) }),
      retry({ delay: (error: unknown) => this.prompUserToRetry(error) }),
      map((scans) => scans.map((scan) => this.mapScandataModel(scan))),
      map((scans) => scans.map((scan) => this.assignWeb3dId(scan))),
      takeUntil(this.cancelGetScandata),
    );
  }

  private prompUserToRetry(error: unknown) {
    return this.showRetryDialog().pipe(
      switchMap((retryClicked) => {
        return retryClicked
          ? this.store.dispatch(new ClearError('scanLoadError'))
          : throwError(() => error);
      }),
    );
  }

  private getAndCacheScandataModel(pointcloudId: string) {
    return this.getScanProjectUrl$(`/pointclouds/${pointcloudId}`).pipe(
      switchMap((url) => this.http.get<ScandataModel>(url)),
      map((scan) => this.mapScandataModel(scan)),
      switchMap((scan) => this.cacheScandataModel(scan)),
    );
  }

  private cacheScandataModel(scan: ScandataModel) {
    return this.store.selectOnce(ScandataState.getScan(scan.id)).pipe(
      switchMap((storeScan) =>
        isNil(storeScan)
          ? this.store.dispatch(new AddScan(this.assignWeb3dId(scan)))
          : this.store.dispatch(new PatchScandataModel(scan)),
      ),
      map(() => scan),
    );
  }

  private scanMatchesFilters(scan: ScandataModel): Observable<boolean> {
    return this.scandataFilterService.getQuerystring().pipe(
      switchMap((querystring) =>
        this.getScanProjectUrl$(`/pointclouds?${querystring},pointcloudId=${scan.id}`),
      ),
      switchMap((url) => this.http.get<PageData<ScandataModel>>(url)),
      map((pageData) => pageData.items.length > 0),
    );
  }

  private cacheScanIfMatchesFilters(scan: ScandataModel) {
    if (isDefined(this.store.selectSnapshot(ScandataState.getScan(scan.id))))
      return this.store.dispatch(new PatchScandataModel(scan)).pipe(map(() => scan));

    const upsertStore = this.store.selectOnce(ScandataState.getScan(scan.id)).pipe(
      switchMap((storeScan) =>
        isNil(storeScan)
          ? this.store.dispatch(new AddScan(this.assignWeb3dId(scan)))
          : this.store.dispatch(new PatchScandataModel(scan)),
      ),
      map(() => scan),
    );

    return this.scanMatchesFilters(scan).pipe(
      switchMap((matchesFilters) => (matchesFilters ? upsertStore : of(scan))),
    );
  }

  private mapScandataModel(scandataModel: ScandataModel): ScandataModel {
    this.assignScandataModelStatus(scandataModel);
    this.convertScandataModelDates(scandataModel);
    return scandataModel;
  }

  private assignScandataModelStatus(scandataModel: ScandataModel) {
    scandataModel.pointcloudStatus = PointcloudStatusMap.get(
      scandataModel.status,
    ) as PointcloudStatus;
  }

  private convertScandataModelDates(scandataModel: ScandataModel) {
    if (isDefined(scandataModel.captureDatetimeUtc))
      scandataModel.captureDatetimeUtc = new Date(scandataModel.captureDatetimeUtc);

    if (isDefined(scandataModel.uploadedDate))
      scandataModel.uploadedDate = new Date(scandataModel.uploadedDate);
  }

  private assignWeb3dId(scan: ScandataModel) {
    scan.web3dId = uuidv4();
    return scan;
  }

  private showRetryDialog() {
    const dialogData = new DialogData(
      'Loading Error',
      'Something went wrong loading your data.',
      {
        text: 'Retry',
        color: 'primary',
      },
      null,
      true,
    );

    return this.dialogService.show(dialogData).pipe(take(1));
  }
}
