import { Inject, Injectable } from '@angular/core';
import { from } from 'rxjs';
import { WINDOW } from '../services/window.provider';

export interface PKCE {
  verifier: string;
  challenge: string;
}

@Injectable({ providedIn: 'root' })
export class PkceProvider {
  constructor(@Inject(WINDOW) private window: Window) {}

  async generatePKCE(): Promise<PKCE> {
    const verifier = this.generateRandomString(43);
    const challenge = await this.createChallenge(verifier);
    return { verifier, challenge };
  }

  generatePKCE$() {
    return from(this.generatePKCE());
  }

  private generateRandomString(length: number = 8): string {
    const array = new Uint8Array(length);
    this.window.crypto.getRandomValues(array);
    const result = this.bufferToString(array);
    return result;
  }

  private bufferToString(buffer: Uint8Array): string {
    const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const array = [];

    for (let i = 0; i < buffer.byteLength; i++) {
      const index = buffer[i] % charSet.length;
      array.push(charSet[index]);
    }
    return array.join('');
  }

  private async createChallenge(verifier: string) {
    const hashed = await this.sha256(verifier);
    const base64encoded = this.base64urlencode(hashed);
    return base64encoded;
  }

  private sha256(input?: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    return this.window.crypto.subtle.digest('SHA-256', data);
  }

  private base64urlencode(buffer: ArrayBuffer) {
    const byteArray = new Uint8Array(buffer);
    const stringArray = new Array<string>();

    for (let i = 0; i < byteArray.byteLength; i++) {
      stringArray.push(String.fromCharCode(byteArray[i]));
    }

    const data = stringArray.join('');
    return this.window.btoa(data).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }
}
