<sd-app-bar
  [loggedIn]="loggedIn()"
  [user]="user()"
  (menuClick)="toggleSidenav($event)"
  (logoutClick)="logout()"
></sd-app-bar>

<mat-sidenav-container class="flex-grow border-t border-gray-1">
  <mat-sidenav
    #sidenav
    mode="side"
    [opened]="sidenavOpen()"
    fixedInViewport
    fixedTopGap="64"
    class="w-[256px] max-w-[256px]"
  >
    <mat-action-list>
      <mat-list-item
        [ngClass]="{ '!bg-blue-light': activeRoute() === 'map-view' }"
        (click)="navigate('map-view')"
      >
        <div class="flex grow justify-start items-center h-8">
          <md-icon class="mr-4">map</md-icon>
          <span class="">Map View</span>
        </div>
      </mat-list-item>

      <mat-list-item
        [ngClass]="{ '!bg-blue-light': activeRoute() === 'list-view' }"
        (click)="navigate('list-view')"
      >
        <div class="flex grow justify-start items-center h-8">
          <md-icon class="mr-4">list_bulleted</md-icon>
          <span>List View</span>
        </div>
      </mat-list-item>

      <mat-list-item
        [ngClass]="{ '!bg-blue-light': activeRoute() === 'config' }"
        (click)="navigate('config')"
      >
        <div class="flex grow justify-start items-center h-8">
          <md-icon class="mr-4">settings</md-icon>
          <span>Config</span>
        </div>
      </mat-list-item>

      <mat-list-item
        [ngClass]="{ '!bg-blue-light': activeRoute() === 'loading-demo' }"
        (click)="navigate('loading-demo')"
      >
        <div class="flex grow justify-start items-center h-8">
          <md-icon class="mr-4">sync</md-icon>
          <button class="bg-transparent">Loading Demo</button>
        </div>
      </mat-list-item>

      <mat-list-item
        [ngClass]="{ '!bg-blue-light': activeRoute() === 'dialog-demo' }"
        (click)="navigate('dialog-demo')"
      >
        <div class="flex grow justify-start items-center h-8">
          <md-icon class="mr-4">launch</md-icon>
          <button class="bg-transparent">Dialog Demo</button>
        </div>
      </mat-list-item>
    </mat-action-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="flex flex-col h-full">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
