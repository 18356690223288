<div class="h-full w-[330px]">
  @switch (optionsPanelView()) {
    @case (view.ListFilters) {
      <sd-list-filters class="block h-full"></sd-list-filters>
    }
    @case (view.DownloadStatus) {
      <sd-download-status class="block h-full"></sd-download-status>
    }
    @case (view.SelectedDetails) {
      <sd-selected-details class="block h-full"></sd-selected-details>
    }
    @case (view.ClassificationEdit) {
      <sd-classification-edit class="block h-full"></sd-classification-edit>
    }
  }
</div>
