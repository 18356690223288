import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ModusButtonModule, ModusIconModule } from '@trimble-gcs/modus';
import { ScandataState } from '../../scandata/scandata.state';
import { Scan3dStyle } from '../models/scan-3d-style';
import { Scan3dStylingComponent } from '../scan-3d-styling/scan-3d-styling.component';
import { Scan3dSettingsClassificationComponent } from './scan-3d-settings-classification/scan-3d-settings-classification.component';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-settings',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusButtonModule,
    ModusIconModule,
    MatTabsModule,
    MatProgressBarModule,
    Scan3dSettingsClassificationComponent,
    Scan3dStylingComponent,
  ],
  templateUrl: './scan-3d-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dSettingsComponent {
  scan3dStyle = input<Scan3dStyle>();
  styleChange = output<Scan3dStyle>();

  isLoading = toSignal(this.store.select(ScandataState.isLoading), { initialValue: false });

  constructor(private store: Store) {}
}
