import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { take } from 'rxjs';
import { CameraState } from '../../connect-3d-ext/camera/camera.state';
import { FitToView } from '../../connect-3d-ext/host-3d.actions';
import { Host3dService } from '../../connect-3d-ext/host-3d.service';
import { Scan3dOptionsPanelView } from '../../scan-3d-panel/scan-3d-options-panel/scan-3d-options-panel.models';
import { PatchScandataModel } from '../../scandata/scandata.actions';
import { ScandataModel } from '../../scandata/scandata.models';
import { Station } from '../../station/station.models';
import { StationState } from '../../station/station.state';
import { ScandataTreeComponent } from '../scandata-tree/scandata-tree.component';

@UntilDestroy()
@Component({
  selector: 'sd-scandata-tree-view',
  standalone: true,
  imports: [CommonModule, ScandataTreeComponent],
  templateUrl: './scandata-tree-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTreeViewComponent {
  currentStation = toSignal(this.store.select(StationState.currentStation));
  cameraProjection = toSignal(this.store.select(CameraState.projection));

  scandata = input.required<ScandataModel[]>();
  optionsPanelView = input.required<Scan3dOptionsPanelView>();
  quotaExceeded = input(false);
  selectedScan = input<ScandataModel | undefined>(undefined);
  selectedStation = input<Station | undefined>(undefined);

  scanSelected = output<ScandataModel | undefined>();
  stationSelected = output<Station | undefined>();

  lastSelectedId = signal<string | undefined>(undefined);

  constructor(
    private host3dService: Host3dService,
    private store: Store,
  ) {}

  modelIconClick(scan: ScandataModel) {
    const show = !scan.showInScene;
    if (show) {
      this.host3dService.showScan(scan).pipe(take(1)).subscribe();
    } else {
      this.host3dService.hideScan(scan).pipe(take(1)).subscribe();
    }
  }

  modelNameClick(model: ScandataModel) {
    if (
      this.selectedScan()?.id === model.id &&
      this.optionsPanelView() !== Scan3dOptionsPanelView.Settings
    ) {
      this.scanSelected.emit(undefined);
      this.lastSelectedId.set(this.selectedStation()?.id);
    } else {
      this.scanSelected.emit(model);
      this.lastSelectedId.set(model.id);
    }
  }

  modelFitToViewClicked(model: ScandataModel) {
    this.store.dispatch(new FitToView([model]));
  }

  toggleExpand(scan: ScandataModel) {
    const expand = !scan.expanded;

    this.store.dispatch(
      new PatchScandataModel({
        id: scan.id,
        expanded: expand,
      }),
    );

    if (expand) {
      this.host3dService.loadScanStations(scan).pipe(take(1)).subscribe();
    }
  }

  stationIconClick(station: Station) {
    // TODO: should be a clear current station method; setCurrentStation clearing is questionable
    this.host3dService.setCurrentStation(station).pipe(take(1)).subscribe();

    if (this.selectedStation()?.id === station.id) {
      this.stationSelected.emit(undefined);
      this.lastSelectedId.set(this.selectedScan()?.id);
    } else {
      this.stationSelected.emit(station);
      this.lastSelectedId.set(station.id);
    }
  }

  stationNameClick(station: Station) {
    this.host3dService.setCurrentStation(station).pipe(take(1)).subscribe();

    if (this.selectedStation()?.id === station.id) {
      this.stationSelected.emit(undefined);
      this.lastSelectedId.set(this.selectedScan()?.id);
    } else {
      this.stationSelected.emit(station);
      this.lastSelectedId.set(station.id);
    }
  }
}
