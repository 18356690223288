<div class="p-4">
  <div class="flex items-center">
    <modus-switch class="flex mr-2.5"></modus-switch>
    <modus-label class="text-xs grow">Station Image</modus-label>
  </div>

  <div class="flex items-center mt-4">
    <modus-switch class="flex mr-2.5"></modus-switch>
    <modus-label class="text-xs grow">Point Cloud</modus-label>
  </div>

  <div class="flex flex-col w-full mt-8">
    <modus-label class="text-xs grow select-none">Point Size</modus-label>
    <mat-slider id="3d-settings-point-size" [min]="1" [max]="10" [step]="1" class="min-w-[60px]">
      <input modus-input matSliderThumb />
    </mat-slider>
  </div>
</div>
