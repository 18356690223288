<mat-tab-group preserveContent="true" class="mds-small h-full overflow-hidden" disableRipple>
  <mat-tab label="Properties" labelClass="!p-0">
    <div class="h-full overflow-y-scroll border-t border-gray-1 p-4">
      <sd-properties
        [scandataModel]="currentModel()"
        [readonly]="true"
        [showTags]="true"
        [showName]="false"
      ></sd-properties>
    </div>
  </mat-tab>
  <mat-tab label="Classification" labelClass="!p-0">
    <div class="h-full border-t border-gray-1">
      <sd-scan-3d-classification
        class="h-full"
        [scandataModel]="currentModel()"
        [scan3dStyle]="scan3dStyle()"
        (styleChange)="onStyleChange($event)"
      ></sd-scan-3d-classification>
    </div>
  </mat-tab>
  <mat-tab label="Styling" labelClass="!p-0">
    <div class="h-full border-t border-gray-1 p-4">
      <sd-scan-3d-styling
        class="h-full"
        [scan3dStyle]="scan3dStyle()"
        (styleChange)="onStyleChange($event)"
      ></sd-scan-3d-styling>
    </div>
  </mat-tab>
</mat-tab-group>
