import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  input,
  output,
  viewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isDefined, isNil } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { delay, filter, merge } from 'rxjs';
import { CameraProjection } from '../../connect-3d-ext/camera/camera.models';
import { getRevisionString } from '../../connect/external-file-id-utils';
import { Scan3dOptionsPanelView } from '../../scan-3d-panel/scan-3d-options-panel/scan-3d-options-panel.models';
import {
  PointcloudStatus,
  ScandataDisplayStatus,
  ScandataLoadStatus,
  ScandataModel,
} from '../../scandata/scandata.models';
import {
  CurrentStation,
  Station,
  StationDisplayStatus,
  StationLoadStatus,
} from '../../station/station.models';
import {
  ScandataTreeIconComponent,
  TreeIcon,
} from '../scandata-tree-icon/scandata-tree-icon.component';

interface TreeItem {
  scan: ScandataModel;
  selected: boolean;
  version?: string;
}

@UntilDestroy()
@Component({
  selector: 'sd-scandata-tree',
  standalone: true,
  imports: [
    CommonModule,
    ModusIconModule,
    ModusTooltipModule,
    ModusButtonModule,
    MatProgressSpinnerModule,
    ScandataTreeIconComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scandata-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTreeComponent {
  scrollContainer = viewChild.required<ElementRef<HTMLDivElement>>('scrollContainer');
  activeElement = viewChild<ElementRef<HTMLDivElement>>('activeElement');

  data = input.required<ScandataModel[]>();
  currentStation = input<CurrentStation>();
  cameraProjection = input.required<CameraProjection>();

  optionsPanelView = input.required<Scan3dOptionsPanelView>();
  lastSelectedId = input<string>();

  quotaExceeded = input(false);
  selectedScan = input<ScandataModel | undefined>(undefined);
  selectedStation = input<Station | undefined>(undefined);

  modelIconClick = output<ScandataModel>();
  modelNameClick = output<ScandataModel>();
  modelExpandClick = output<ScandataModel>();
  modelFitToViewClick = output<ScandataModel>();

  stationIconClick = output<Station>();
  stationNameClick = output<Station>();

  stationDisabledReason = computed(() =>
    this.cameraProjection() === CameraProjection.Orthographic
      ? 'Station view disabled in orthogonal mode.'
      : '',
  );

  stationTooltipDisabled = computed(
    () => this.cameraProjection() !== CameraProjection.Orthographic,
  );

  treeData = this.getTreeData();
  treeIconType = TreeIcon;

  constructor() {
    merge(toObservable(this.selectedScan), toObservable(this.selectedStation))
      .pipe(filter(isDefined), delay(200), untilDestroyed(this))
      .subscribe(() => {
        this.scrollActiveElementIntoView();
      });
  }

  scrollActiveElementIntoView() {
    const scrollContainer = this.scrollContainer();
    if (isNil(scrollContainer)) return;

    const activeElement = this.activeElement();
    if (isNil(activeElement)) return;

    const containerOffsetTop = scrollContainer.nativeElement.offsetTop;
    const containerHeight = scrollContainer.nativeElement.offsetHeight;

    const elementBounds = activeElement.nativeElement.getBoundingClientRect();
    const elementTop = elementBounds.top - containerOffsetTop;
    const elementBottom = elementBounds.bottom - containerOffsetTop;

    if (elementTop >= 0 && elementBottom <= containerHeight) return;

    this.activeElement()?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  modelIconClicked(model: ScandataModel) {
    this.modelIconClick.emit(model);
  }

  modelNameClicked(model: ScandataModel) {
    this.modelNameClick.emit(model);
  }

  modelFitToViewClicked(model: ScandataModel) {
    this.modelFitToViewClick.emit(model);
  }

  stationIconClicked(station: Station) {
    this.stationIconClick.emit(station);
  }

  stationNameClicked(station: Station) {
    if (this.loadStationDisabled()) return;

    this.stationNameClick.emit(station);
  }

  toggleExpand(model: ScandataModel) {
    this.modelExpandClick.emit(model);
  }

  stationsLoading(model: ScandataModel) {
    return model.stationLoadStatus === StationLoadStatus.Loading;
  }

  fitToViewDisabled(model: ScandataModel) {
    return (
      model.displayStatus !== ScandataDisplayStatus.Displayed || isDefined(this.currentStation())
    );
  }

  loadModelDisabled(model: ScandataModel) {
    return (
      model.pointCount === 0 ||
      model.pointcloudStatus !== PointcloudStatus.Ready ||
      this.quotaExceeded()
    );
  }

  modelNotReady(model: ScandataModel) {
    return (
      model.pointcloudStatus === PointcloudStatus.Uploading ||
      model.pointcloudStatus === PointcloudStatus.Processing
    );
  }

  modelHasError(model: ScandataModel) {
    return this.getModelIcon(model) === TreeIcon.Error;
  }

  getModelInfoMessage(model: ScandataModel) {
    if (this.modelNotReady(model)) return 'Not ready for viewing';

    if (model.pointcloudStatus === PointcloudStatus.Failed) {
      return 'Failed to ingest';
    }

    if (
      model.loadStatus === ScandataLoadStatus.LoadingError ||
      model.displayStatus === ScandataDisplayStatus.DisplayError
    ) {
      return 'Failed to load';
    }

    if (model.pointCount === 0) {
      return 'Stations only';
    }

    return null;
  }

  getModelIcon(model: ScandataModel): TreeIcon {
    if (this.modelNotReady(model) || this.quotaExceeded()) return TreeIcon.Unavailable;

    if (model.pointcloudStatus === PointcloudStatus.Failed) {
      return TreeIcon.Error;
    }

    if (
      model.loadStatus === ScandataLoadStatus.LoadingError ||
      model.displayStatus === ScandataDisplayStatus.DisplayError
    ) {
      return TreeIcon.Error;
    }

    if (
      model.loadStatus === ScandataLoadStatus.Loading ||
      model.displayStatus === ScandataDisplayStatus.AwaitingDisplay
    ) {
      return TreeIcon.Loading;
    }

    if (!model.showInScene) {
      return TreeIcon.Off;
    }

    return TreeIcon.On;
  }

  stationHasError(station: Station) {
    return this.getStationIcon(station) === TreeIcon.Error;
  }

  getStationErrorMessage(station: Station) {
    if (this.getStationIcon(station) === TreeIcon.Error) {
      return 'Failed to load';
    }

    return null;
  }

  getStationIcon(station: Station): TreeIcon {
    if (this.quotaExceeded()) return TreeIcon.Unavailable;

    const currentStation = this.currentStation();
    const viewed = station.id === currentStation?.station.id;
    if (viewed) {
      if (currentStation.displayStatus === StationDisplayStatus.AwaitingDisplay) {
        return TreeIcon.Loading;
      }

      if (currentStation.displayStatus === StationDisplayStatus.DisplayError) {
        return TreeIcon.Error;
      }

      if (currentStation.displayStatus === StationDisplayStatus.Displayed) {
        return TreeIcon.On;
      }

      return TreeIcon.Off;
    } else {
      return TreeIcon.Off;
    }
  }

  loadStationDisabled() {
    const currentStation = this.currentStation();

    return (
      currentStation?.displayStatus === StationDisplayStatus.AwaitingDisplay ||
      this.cameraProjection() === CameraProjection.Orthographic ||
      this.quotaExceeded()
    );
  }

  private getTreeData() {
    return computed(() => {
      const data = this.data();
      const selectedScan = this.selectedScan();

      return data.map(
        (scan) =>
          <TreeItem>{
            scan,
            selected: scan.id === selectedScan?.id,
            version: getRevisionString(scan.externalFileId ?? '') ?? undefined,
          },
      );
    });
  }
}
