import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { EMPTY, filter, map, switchMap, take } from 'rxjs';
import { SetConnectRegion, SetProject } from '../../../app-state/app.actions';
import { AppState } from '../../../app-state/app.state';
import { ConnectRegionService } from '../../../connect/connect-region.service';
import { DialogService } from '../../../dialog/dialog.service';
import {
  ProjectSelectDialogComponent,
  projectSelectDialogDefaultConfig,
  ProjectSelectDialogResult,
} from './project-select-dialog.component';
import { ProjectSelectState } from './project-select.state';

@UntilDestroy()
@Component({
  selector: 'sd-project-select',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './project-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectComponent {
  private project = this.store.selectSignal(AppState.project);
  projectName = computed(() => this.project()?.name ?? '');

  constructor(
    private store: Store,
    private connectRegionService: ConnectRegionService,
    private dialogService: DialogService,
  ) {
    this.setProjectFromCacheOrDefault();
  }

  showProjectPicker() {
    this.dialogService
      .showComponent<ProjectSelectDialogComponent, any, ProjectSelectDialogResult>(
        ProjectSelectDialogComponent,
        projectSelectDialogDefaultConfig,
      )
      .pipe(
        filter(isDefined),
        switchMap(({ project, region }) =>
          this.store.dispatch([new SetProject(project), new SetConnectRegion(region)]),
        ),
      )
      .subscribe(() => {
        window.location.reload();
      });
  }

  private setProjectFromCacheOrDefault() {
    this.getProjects()
      .pipe(
        map((projects) => {
          const cachedProject = this.store.selectSnapshot(ProjectSelectState.cachedProject);
          const project = projects.find((project) => project.id === cachedProject?.id);
          return isDefined(project) ? project : projects.at(0);
        }),
        switchMap((project) => {
          return isDefined(project) ? this.store.dispatch(new SetProject(project)) : EMPTY;
        }),
        take(1),
      )
      .subscribe();
  }

  private getProjects() {
    return this.store.selectOnce(ProjectSelectState.cachedConnectRegion).pipe(
      switchMap((connectRegion) => {
        return isNil(connectRegion)
          ? this.connectRegionService.getProjects()
          : this.connectRegionService.getProjectsForRegion(connectRegion);
      }),
    );
  }
}
