import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { catchError, concatMap, from, map, mergeMap, Observable, of, switchMap } from 'rxjs';
import { AppState } from '../app-state/app.state';
import { injectLogger, Logger } from '../logging/logger';
import { ProjectQuotaService } from '../quota/project-quota.service';
import { ScandataModel } from '../scandata/scandata.models';
import {
  GetClientIdentificationHeaders,
  IngestionSource,
} from '../utils/client-identification-headers';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import { PatchImportScan, SetImportScans } from './import.actions';
import { ImportFile, ImportFileStatus, ImportScan, ImportScanStatus } from './import.models';
import { UploadFile } from './upload.models';
import { UploadService } from './upload.service';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);
  private readonly logger = injectLogger(Logger, 'ImportService');

  constructor(
    private projectQuotaService: ProjectQuotaService,
    private uploadService: UploadService,
    private store: Store,
    private http: HttpClient,
  ) {}

  cancelImport(importScans: ImportScan[]) {
    return from(importScans).pipe(switchMap((importScan) => this.deleteScan(importScan)));
  }

  import(importScans: ImportScan[]): Observable<ImportScan[]> {
    return this.store.dispatch(new SetImportScans(importScans)).pipe(
      mergeMap(() => importScans),
      concatMap((importScan) => this.importScan(importScan)),
      map(() => importScans),
    );
  }

  private importScan(importScan: ImportScan) {
    return this.checkAvailableQuota(importScan).pipe(
      switchMap((importScan) => this.createPointcloud(importScan)),
      switchMap((importScan) => this.createFiles(importScan)),
      switchMap((importScan) => this.uploadFiles(importScan)),
      switchMap((importScan) => this.createIngestion(importScan)),
      switchMap((importScan) =>
        this.updateImportScanStatus(importScan, ImportScanStatus.Completed),
      ),
      catchError(() => this.deleteScan(importScan)),
    );
  }

  private setAndThrowImportScanError(importScan: ImportScan, errorMessage: string) {
    importScan.status = ImportScanStatus.Error;
    importScan.errorMessage = errorMessage;

    //mark pending files as skipped
    importScan.files
      .filter((importFile) => importFile.status === ImportFileStatus.Pending)
      .forEach((importFile) => {
        importFile.status = ImportFileStatus.Skipped;
      });

    return this.store.dispatch(new PatchImportScan(importScan)).pipe(
      map(() => {
        throw new Error(errorMessage);
      }),
    );
  }

  private updateImportScanStatus(importScan: ImportScan, status: ImportScanStatus) {
    importScan.status = status;
    return this.store.dispatch(new PatchImportScan(importScan)).pipe(map(() => importScan));
  }

  private checkAvailableQuota(importScan: ImportScan): Observable<ImportScan> {
    const importSize = importScan.files.reduce((acc, cur) => acc + cur.size, 0);

    return this.updateImportScanStatus(importScan, ImportScanStatus.CheckQuota).pipe(
      switchMap(() => this.projectQuotaService.quotaExceeded(importSize)),
      catchError((err) => {
        this.logger.error(`Import error checking quota`, {}, err);
        return this.setAndThrowImportScanError(importScan, 'Error checking quota');
      }),
      switchMap((quotaExceeded) => {
        if (quotaExceeded) {
          return this.setAndThrowImportScanError(importScan, 'Quota will be exceeded');
        }

        return of(importScan);
      }),
    );
  }

  private createPointcloud(importScan: ImportScan): Observable<ImportScan> {
    const appSettings = this.store.selectSnapshot(AppState.settings);

    return this.updateImportScanStatus(importScan, ImportScanStatus.CreatePointcloud).pipe(
      switchMap(() => this.getScanProjectUrl$(`/pointclouds`)),
      switchMap((url) => {
        return this.http.post<ScandataModel>(
          url,
          {
            name: importScan.name,
          },
          {
            headers: GetClientIdentificationHeaders(appSettings, IngestionSource.Import),
          },
        );
      }),
      map((scan) => {
        importScan.scan = scan;
        return importScan;
      }),
      catchError((err) => {
        this.logger.error(`Import error creating pointcloud`, {}, err);
        return this.setAndThrowImportScanError(importScan, 'Error creating pointcloud');
      }),
    );
  }

  private createFiles(importScan: ImportScan): Observable<ImportScan> {
    return this.updateImportScanStatus(importScan, ImportScanStatus.CreateFiles).pipe(
      switchMap((importScan) =>
        this.getScanProjectUrl$(`/pointclouds/${importScan.scan!.id}/files`),
      ),
      switchMap((url) => {
        return this.http.post<UploadFile[]>(url, {
          files: importScan.files.map((importFile) => ({
            name: importFile.name,
            size: importFile.size,
          })),
        });
      }),
      map((uploadFiles) => {
        importScan.files = this.mapImportFileUrl(importScan.files, uploadFiles);
        return importScan;
      }),
      catchError((err) => {
        this.logger.error(`Import error creating files`, {}, err);
        return this.setAndThrowImportScanError(importScan, 'Error creating files');
      }),
    );
  }

  private mapImportFileUrl(importFiles: ImportFile[], uploadFiles: UploadFile[]) {
    return uploadFiles.map((uploadFile) => {
      const importFile = importFiles.find((importFile) => {
        return (
          importFile.name === uploadFile.name &&
          importFile.size === uploadFile.size &&
          isNil(importFile.fileUpload?.uploadUrl)
        );
      })!;

      importFile.fileUpload!.uploadUrl = uploadFile.uploadLink;

      return importFile;
    });
  }

  private uploadFiles(importScan: ImportScan): Observable<ImportScan> {
    return this.updateImportScanStatus(importScan, ImportScanStatus.UploadFiles).pipe(
      switchMap((importScan) => this.uploadService.uploadFiles(importScan.files)),
      map((importFiles) => {
        importScan.files = importFiles;
        return importScan;
      }),
      catchError((err) => {
        this.logger.error(`Error during upload`, {}, err);
        return this.setAndThrowImportScanError(importScan, 'Error during upload');
      }),
    );
  }

  private createIngestion(importScan: ImportScan) {
    return this.updateImportScanStatus(importScan, ImportScanStatus.Ingest).pipe(
      switchMap((importScan) =>
        this.getScanProjectUrl$(`/pointclouds/${importScan.scan!.id}/ingestions`),
      ),
      switchMap((url) => this.http.post(url, null)),
      map(() => importScan),
      catchError((err) => {
        this.logger.error(`Import error creating ingestion`, {}, err);
        return this.setAndThrowImportScanError(importScan, 'Error starting ingestion');
      }),
    );
  }

  private deleteScan(importScan: ImportScan) {
    if (isNil(importScan.scan)) return of(importScan);

    return this.getScanProjectUrl$(`/pointclouds/${importScan.scan.id}`).pipe(
      switchMap((url) => {
        return this.http.delete<void>(url);
      }),
      catchError((err) => {
        this.logger.error(`Import error deleting pointcloud`, {}, err);
        return of(null);
      }),
      map(() => importScan),
    );
  }
}
