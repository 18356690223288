<div class="h-full overflow-y-scroll p-4" (scroll)="hideColorPicker()">
  @if (hasClassifications()) {
    <label class="text-xs">Distribution</label>
    <sd-classification-graph
      [classifications]="classifications()"
      [activeClassification]="activeClassification()"
      (activeClassificationChanged)="setActiveClassification($event)"
      class="flex mt-1 h-[235px]"
    ></sd-classification-graph>
    <div class="flex mt-3 mb-2 items-center">
      <modus-label class="text-xs grow">Classes & Point Styling</modus-label>
      <modus-switch
        id="3d-settings-classification-show-toggle"
        class="flex mr-2.5"
        modus-tooltip="Show Classification Colors"
        [formControl]="showClassificationControl"
      ></modus-switch>
    </div>
    <ol class="mt-3">
      @for (item of classifications(); track item.classificationScheme.id) {
        <li
          (mouseover)="setActiveClassification(item)"
          (mouseout)="setActiveClassification()"
          [ngClass]="{ 'bg-gray-0': isActiveClassification(item) }"
          class="flex items-center text-xs p-y-2 pr-2 mb-2 hover:bg-gray-0 border-y border-gray-1 border-opacity-40"
        >
          <button
            id="3d-settings-classification-item-visible-toggle"
            class="min-w-8"
            modus-icon-button
            (click)="changeVisibility(item)"
          >
            <md-icon
              class="!text-xl"
              [ngClass]="{ 'opacity-60': !item.classificationScheme.visible }"
              >{{ item.classificationScheme.visible ? 'visibility_on' : 'visibility_off' }}</md-icon
            >
          </button>
          <span class="grow overflow-hidden text-ellipsis whitespace-nowrap pl-1 pr-1">
            {{ item.classificationScheme.name }}
          </span>
          <span modus-tooltip="{{ item.percentage | percentage: 2 }}">{{
            item.percentage | percentage: 0
          }}</span>
          <div class="relative flex items-center ml-2 min-w-9">
            <input
              id="3d-settings-classification-color"
              colorPicker="{{ stripAlpha(item.classificationScheme.rgba) }}"
              [cpOutputFormat]="'hex'"
              [cpAlphaChannel]="'disabled'"
              [cpPosition]="'top'"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'modus-button modus-button-medium modus-basic-button'"
              [cpOKButton]="true"
              [cpOKButtonClass]="'modus-button modus-button-medium modus-flat-button-primary'"
              [cpWidth]="'180'"
              [cpUseRootViewContainer]="true"
              (cpToggleChange)="colorPickerToggle($event)"
              (colorPickerChange)="changeColor($event, item)"
              style="background-color: #{{ item.classificationScheme.rgba }}"
              class="absolute h-4 w-full border border-gray-0 rounded-2xl cursor-pointer"
              readonly
            />
          </div>
        </li>
      }
    </ol>
    @if (unallocatedClassificationCount(); as count) {
      @if (count > 0) {
        <label class="text-xs flex grow justify-center italic"
          >{{ count }} other {{ count === 1 ? 'class' : 'classes' }} with zero value not shown
        </label>
      }
    }
  } @else {
    No classification data.
  }
</div>
