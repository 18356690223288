<div class="flex flex-col h-full">
  <!-- header -->
  <div mat-dialog-title>
    <div class="flex flex-1 flex-wrap items-center font-bold">
      {{ data.title }}
    </div>
    <button modus-icon-button modus-tooltip="Close" (click)="cancelClick()">
      <md-icon>close</md-icon>
    </button>
  </div>

  <!-- content -->
  <mat-dialog-content>
    <span class="pt-4 whitespace-pre-wrap">{{ data.message }}</span>
  </mat-dialog-content>

  <!-- actions -->
  <mat-dialog-actions>
    @if (data.cancelButton) {
      <button
        modus-stroked-button
        [color]="data.cancelButton.color"
        class="mr-2"
        (click)="cancelClick()"
      >
        {{ data.cancelButton.text }}
      </button>
    }
    <button modus-flat-button [color]="data.okButton.color" (click)="okClick()">
      {{ data.okButton.text }}
    </button>
  </mat-dialog-actions>
</div>
