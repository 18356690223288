import { decorateProperty as o } from "./base.js";
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var n;
const e = null != (null === (n = window.HTMLSlotElement) || void 0 === n ? void 0 : n.prototype.assignedElements) ? (o, n) => o.assignedElements(n) : (o, n) => o.assignedNodes(n).filter(o => o.nodeType === Node.ELEMENT_NODE);
function l(n) {
  const {
    slot: l,
    selector: t
  } = null != n ? n : {};
  return o({
    descriptor: o => ({
      get() {
        var o;
        const r = "slot" + (l ? `[name=${l}]` : ":not([name])"),
          i = null === (o = this.renderRoot) || void 0 === o ? void 0 : o.querySelector(r),
          s = null != i ? e(i, n) : [];
        return t ? s.filter(o => o.matches(t)) : s;
      },
      enumerable: !0,
      configurable: !0
    })
  });
}
export { l as queryAssignedElements };
