import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AddTilingWorkflow, RemoveTilingWorkflow } from './workflow.actions';

export interface WorkflowStateModel {
  tiling: number;
}

const defaultState: WorkflowStateModel = {
  tiling: 0,
};

@State<WorkflowStateModel>({
  name: 'workflowStateModel',
  defaults: defaultState,
})
@Injectable()
export class WorkflowState {
  @Selector() static tiling(state: WorkflowStateModel): number {
    return state.tiling;
  }

  @Action(AddTilingWorkflow) addTilingWorkflow(ctx: StateContext<WorkflowStateModel>) {
    const tiling = ctx.getState().tiling + 1;
    ctx.patchState({ tiling });
  }

  @Action(RemoveTilingWorkflow) removeTilingWorkflow(ctx: StateContext<WorkflowStateModel>) {
    const currentTiling = ctx.getState().tiling;
    const tiling = currentTiling > 1 ? currentTiling - 1 : 0;
    ctx.patchState({ tiling });
  }
}
