import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  input,
  output,
} from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusIconModule, ModusSwitchModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { Station } from '../../station/station.models';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-station-detail',
  standalone: true,
  imports: [CommonModule, ModusTooltipModule, ModusIconModule, ModusSwitchModule, MatSliderModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scan-3d-station-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dStationDetailComponent {
  currentStation = input.required<Station>();
  exitStationClick = output();

  currentStationName = computed(() => this.currentStation().name);
}
