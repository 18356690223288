<sd-scandata-tree
  [data]="scandata()"
  [currentStation]="currentStation()"
  [cameraProjection]="cameraProjection()!"
  [optionsPanelView]="optionsPanelView()"
  [quotaExceeded]="quotaExceeded()"
  [selectedScan]="selectedScan()"
  [selectedStation]="selectedStation()"
  [lastSelectedId]="lastSelectedId()"
  (modelIconClick)="modelIconClick($event)"
  (modelNameClick)="modelNameClick($event)"
  (modelExpandClick)="toggleExpand($event)"
  (modelFitToViewClick)="modelFitToViewClicked($event)"
  (stationIconClick)="stationIconClick($event)"
  (stationNameClick)="stationNameClick($event)"
  class="h-full"
></sd-scandata-tree>
