import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  input,
  output,
  viewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isDefined, isNil } from '@trimble-gcs/common';
import Chart from 'chart.js/auto';
import { ActiveElement } from 'chart.js/dist/plugins/plugin.tooltip';
import { Classification } from '../../../../../classification/classification.model';
import { PercentagePipe } from '../../../../../pipes/percentage.pipe';

@UntilDestroy()
@Component({
  selector: 'sd-classification-graph',
  standalone: true,
  imports: [],
  templateUrl: './classification-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassificationGraphComponent {
  classifications = input.required<Classification[]>();
  activeClassification = input.required<Classification | null>();

  activeClassificationChanged = output<Classification | null>();

  private chartRef = viewChild.required<ElementRef<HTMLCanvasElement>>('chart');
  private chart = this.getChartSignal();

  constructor(private percentagePipe: PercentagePipe) {
    this.createClassificationsEffect();
    this.createActiveClassificationEffect();
  }

  private getChartSignal() {
    return computed(() => {
      const canvas = this.chartRef()?.nativeElement;
      return isDefined(canvas) ? this.createChart(canvas) : null;
    });
  }

  private createChart(canvas: HTMLCanvasElement) {
    const percentagePipe = this.percentagePipe;

    const chart = new Chart(canvas, {
      type: 'bar',
      data: { datasets: [] },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              title(tooltipItems) {
                //format display value with 2 decimals
                const value = (tooltipItems[0].raw as number) * 100;
                tooltipItems[0].formattedValue = percentagePipe.transform(value, 2);

                //break tooltip title into multi line if length exceeds n
                const maxLineChars = 40;
                const title = tooltipItems[0].label;
                const words = title.split(' ');
                const paragraph = [''];

                words.map((word) => {
                  const lastLine = paragraph.slice(-1);
                  const combined = [lastLine, word].join(' ').trim();
                  if (combined.length < maxLineChars) {
                    paragraph.pop();
                    paragraph.push(combined);
                  } else {
                    paragraph.push(word);
                  }
                });

                return paragraph;
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              callback(tickValue) {
                //add percentage symbol to zero scale and drop decimal from scale
                const value = typeof tickValue === 'string' ? parseInt(tickValue) : tickValue;
                return `${Math.round(value * 100)}%`;
              },
              format: {
                style: 'percent',
              },
            },
            border: { display: false },
          },
          x: {
            ticks: {
              callback(tickValue) {
                // truncate the labels on x axis
                // open issue on github: https://github.com/chartjs/Chart.js/issues/10466
                const maxLabelLength = 15;
                const label = this.getLabelForValue(tickValue as number);
                if (typeof label === 'string' && label.length > maxLabelLength) {
                  return `${label.substring(0, maxLabelLength)}...`;
                }
                return label;
              },
              minRotation: 90,
            },
            grid: { lineWidth: 0 },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        onHover: (_, activeElements) => {
          this.chartActiveElementChanged(activeElements[0]);
        },
      },
    });

    return chart;
  }

  private createClassificationsEffect() {
    effect(() => {
      const chart = this.chart();
      const classifications = this.classifications();

      if (isNil(chart) || isNil(classifications)) return;

      const chartLabels = classifications.map(
        (item) => `${item.classificationScheme.id} - ${item.classificationScheme.name}`,
      );
      const chartData = classifications.map((item) => item.percentage / 100);
      const chartColorsRGBA = classifications.map((item) => `#${item.classificationScheme.rgba}`);

      chart.data = {
        labels: chartLabels,
        datasets: [
          {
            data: chartData,
            maxBarThickness: 20,
            backgroundColor: chartColorsRGBA,
          },
        ],
      };

      chart.update();
    });
  }

  private createActiveClassificationEffect() {
    effect(() => {
      const chart = this.chart();
      const classifications = this.classifications();
      const active = this.activeClassification();

      if (isNil(chart) || isNil(classifications)) return;

      if (isNil(active)) {
        chart.setActiveElements([]);
        chart.tooltip?.setActiveElements([], { x: 0, y: 0 });
        chart.update();
        return;
      }

      const activeIndex = classifications.findIndex(
        (x) => x.classificationScheme.id === active.classificationScheme.id,
      );
      if (chart.tooltip) {
        chart.tooltip.setActiveElements(
          [
            {
              datasetIndex: 0,
              index: activeIndex,
            },
          ],
          {
            x: 0,
            y: 0,
          },
        );
      }

      chart.setActiveElements([
        {
          datasetIndex: 0,
          index: activeIndex,
        },
      ]);

      chart.update();
    });
  }

  private chartActiveElementChanged(activeElement: ActiveElement) {
    if (isNil(activeElement)) {
      this.activeClassificationChanged.emit(null);
      return;
    }

    const classification = this.classifications()[activeElement.index];
    this.activeClassificationChanged.emit(classification);
  }
}
