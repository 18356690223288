import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  output,
  viewChildren,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusSwitchModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { ColorPickerDirective, ColorPickerModule } from 'ngx-color-picker';
import { ClassificationScheme } from '../../../classification/classification-scheme.model';
import { colorHexStripAlpha } from '../../../utils/color-converter';
import { Scan3dStyle } from '../../models/scan-3d-style';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-settings-classification',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusSwitchModule,
    ModusButtonModule,
    ModusTooltipModule,
    ModusIconModule,
    ColorPickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scan-3d-settings-classification.component.html',
  styles: [
    `
      :host {
        // TODO: add custom style
        ::ng-deep color-picker {
          position: fixed;
          top: 2px;
          left: -70px;
          z-index: 1000;

          .color-picker .arrow.arrow-top {
            left: 165px;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dSettingsClassificationComponent {
  colorPickers = viewChildren<ColorPickerDirective>(ColorPickerDirective);

  scan3dStyle = input.required<Scan3dStyle>();

  styleChange = output<Scan3dStyle>();

  classificationSchemes = computed(() => this.scan3dStyle().classificationSchemes);
  showClassificationControl = new FormControl<boolean>(false, { nonNullable: true });

  constructor() {
    this.createShowClassificationControlEffect();
    this.subscribeToFormChanges();
  }

  changeVisibility(classificationScheme: ClassificationScheme) {
    classificationScheme.visible = !classificationScheme.visible;

    const schemes = this.getClassificationSchemeChanges(classificationScheme);
    this.updateGlobalStyle({ classificationSchemes: schemes });
  }

  changeColor(color: string, classificationScheme: ClassificationScheme) {
    classificationScheme.rgba = color.replace('#', '').toUpperCase();

    const schemes = this.getClassificationSchemeChanges(classificationScheme);
    this.updateGlobalStyle({ classificationSchemes: schemes });
  }

  stripAlpha(rgba: string) {
    return colorHexStripAlpha(rgba);
  }

  hideColorPicker() {
    this.colorPickers().forEach((input) => {
      input.closeDialog();
    });
  }

  private createShowClassificationControlEffect() {
    effect(() => {
      const scan3dStyle = this.scan3dStyle();
      this.showClassificationControl.setValue(scan3dStyle.showClassification, {
        emitEvent: false,
      });
    });
  }

  private subscribeToFormChanges() {
    this.showClassificationControl.valueChanges.pipe(untilDestroyed(this)).subscribe((show) => {
      this.updateGlobalStyle({ showClassification: show });
    });
  }

  private getClassificationSchemeChanges(classificationScheme: ClassificationScheme) {
    return this.classificationSchemes().map((scheme) =>
      scheme.id === classificationScheme.id ? classificationScheme : scheme,
    );
  }

  private updateGlobalStyle(style: Partial<Scan3dStyle>) {
    const mergedStyle: Scan3dStyle = {
      ...this.scan3dStyle(),
      ...style,
    };
    this.styleChange.emit(mergedStyle);
  }
}
