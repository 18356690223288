import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { map, Observable, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { ConnectRegionService } from '../../../connect/connect-region.service';
import { ConnectRegion } from '../../../connect/connect.models';
import { LoadingService } from '../../../loading/loading.service';
import { ProjectSelectState } from './project-select.state';

export const projectSelectDialogDefaultConfig: MatDialogConfig = {
  disableClose: true,
  height: '70%',
  minHeight: '300px',
  width: '400px',
};

export type ProjectSelectDialogResult = {
  region: ConnectRegion;
  project: ConnectProject;
};

@UntilDestroy()
@Component({
  selector: 'sd-project-select-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusTooltipModule,
    ModusIconModule,
    ModusButtonModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDialogModule,
  ],
  templateUrl: './project-select-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectDialogComponent {
  regionFormControl = new FormControl<ConnectRegion | null>(null);

  isLoading$: Observable<boolean>;
  regions$: Observable<ConnectRegion[]>;

  projects!: ConnectProject[];

  constructor(
    private dialogRef: MatDialogRef<ProjectSelectDialogComponent, ProjectSelectDialogResult>,
    private store: Store,
    private connectRegionService: ConnectRegionService,
    private loadingService: LoadingService,
  ) {
    this.isLoading$ = this.loadingService.isLoading$(this);
    this.regions$ = this.getRegions();

    this.subscribeToIsLoadingChange();
    this.subscribeToRegionChange();
    this.setRegionInitialValue();
  }

  cancelClick(): void {
    this.dialogRef.close();
  }

  projectClick(project: ConnectProject) {
    const result: ProjectSelectDialogResult = {
      region: this.regionFormControl.value!,
      project,
    };
    this.dialogRef.close(result);
  }

  private getRegions() {
    return this.connectRegionService
      .getConnectRegions()
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  }

  private setRegionInitialValue() {
    this.regions$
      .pipe(
        map((regions) => {
          const cachedRegion = this.store.selectSnapshot(ProjectSelectState.cachedConnectRegion);
          const region = regions.find((region) => region.location === cachedRegion?.location);
          if (isDefined(region)) return region;

          return regions.at(0);
        }),
        take(1),
      )
      .subscribe((region) => {
        this.regionFormControl.setValue(region ?? null);
      });
  }

  private subscribeToIsLoadingChange() {
    this.isLoading$.pipe(untilDestroyed(this)).subscribe((isLoading) => {
      if (isLoading) this.regionFormControl.disable({ emitEvent: false });
      else this.regionFormControl.enable({ emitEvent: false });
    });
  }

  private subscribeToRegionChange() {
    this.regionFormControl.valueChanges
      .pipe(
        tap(() => (this.projects = [])),
        switchMap((region) => {
          if (isNil(region)) return of([]);

          return this.loadingService.loadFrom(
            this.connectRegionService.getProjectsForRegion(region),
            this,
          );
        }),
        untilDestroyed(this),
      )
      .subscribe((projects) => {
        this.projects = projects;
      });
  }
}
