import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  input,
  output,
  viewChild,
} from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { isDefined, isNil, isNumeric } from '@trimble-gcs/common';
import { ModusCheckboxModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { FileSizePipe, PointCountPipe } from '@trimble-gcs/ngx-common';
import { ChipContainerComponent } from '../../chip-container/chip-container.component';
import { getRevisionString } from '../../connect/external-file-id-utils';
import { SortInfo } from '../../scandata/scandata-query.models';
import { PointcloudStatus, ScandataModel } from '../../scandata/scandata.models';

interface RowData {
  scan: ScandataModel;
  statusIcon: StatusIcon;
  thumbnail: Thumbnail;
  version?: string;
}

interface StatusIcon {
  icon: string;
  color: string;
  message: string;
}

enum Thumbnail {
  None = 'None',
  Busy = 'Busy',
  Preview = 'Preview',
  Station = 'Station',
}

@Component({
  selector: 'sd-scandata-table',
  standalone: true,
  imports: [
    CommonModule,
    ModusIconModule,
    ModusCheckboxModule,
    ModusTooltipModule,
    MatTableModule,
    MatSortModule,
    FileSizePipe,
    PointCountPipe,
    ChipContainerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scandata-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTableComponent implements AfterViewInit {
  private readonly sort = viewChild.required<MatSort>(MatSort);

  dataSource = computed(() => {
    const data = this.data();
    const sort = this.sort();

    const dataSource = new MatTableDataSource<RowData>();
    dataSource.sortData = (data, sort) => this.sortData(data, sort);
    dataSource.data = data;
    dataSource.sort = sort;

    return dataSource;
  });

  readonly displayedColumns = [
    'selected',
    'thumbnailUrl',
    'name',
    'status',
    'uploadedBy',
    'uploadedDate',
    'pointCount',
    'fileSize',
    'tags',
  ];

  sortInfo = input.required<SortInfo>();
  lastSelectedId = input<string>();

  data = input.required<RowData[], ScandataModel[]>({
    transform: (value) => {
      return value.map((scan) => ({
        scan,
        statusIcon: this.getIcon(scan),
        thumbnail: this.getThumbnail(scan),
        version: getRevisionString(scan.externalFileId ?? '') ?? undefined,
      }));
    },
  });

  selectionChange = output<ScandataModel[]>();
  chipClick = output<ScandataModel>();
  sortInfoChange = output<SortInfo>();

  isAllSelected = computed(() => {
    const data = this.data();
    return data.length > 0 && data.every((row) => row.scan.selected);
  });

  isSomeSelected = computed(() => {
    const data = this.data();
    return data.some((row) => row.scan.selected) && data.some((row) => !row.scan.selected);
  });

  thumbnail = Thumbnail;

  constructor() {
    this.createSortInfoEffect();
  }

  ngAfterViewInit(): void {
    this.sort().disableClear = true;
  }

  getScanId(index: number, rowData: RowData) {
    return rowData.scan.id;
  }

  toggleAllRows() {
    const selected = !this.isAllSelected();

    /**
     * The mat table does not have a property to get the data as it is currently sorted in the UI,
     * so we manually sort it below when selecting all the scans.
     *
     * When selecting scans we emit the change in the order as the scans appears in the UI. This
     * enables tracking the order of selection so that it can be displayed in the same order on
     * the selected options panel. When unselecting a scan we don't need the sort order.
     */
    const dataSource = this.dataSource();
    const data = selected ? dataSource.sortData(dataSource.data, this.sort()) : dataSource.data;
    const changedScans = data.map((row) => <ScandataModel>{ ...row.scan, selected });

    this.selectionChange.emit(changedScans);
  }

  checkboxKeyDown(rowData: RowData) {
    this.toggleScanSelect(rowData.scan);
  }

  rowClicked(mouseEvent: MouseEvent, rowData: RowData, index: number) {
    if (mouseEvent.shiftKey) return this.toggleRangeSelected(rowData.scan, index);

    const checkboxClicked = isEventFromModusCheckbox(mouseEvent);
    if (mouseEvent.ctrlKey || checkboxClicked) return this.toggleScanSelect(rowData.scan);

    this.toggleSingleSelected(rowData.scan);
  }

  onChipClick(event: Event, rowData: RowData) {
    event.stopPropagation();

    const dataSource = this.dataSource();
    const currentSelection = dataSource.data.filter((row) => row.scan.selected);
    currentSelection.forEach((row) => (row.scan.selected = false));

    const scan = rowData.scan;
    if (!scan.selected) scan.selected = true;

    this.chipClick.emit(scan);
  }

  onSortChange(sort: Sort) {
    const sortInfo = this.sortInfo();
    const sortChanged =
      sortInfo.sortBy !== sort.active || sortInfo.sortDirection !== sort.direction;
    if (!sortChanged) return;

    const changedSortInfo: SortInfo = {
      sortBy: sort.active,
      sortDirection: sort.direction,
    };

    this.sortInfoChange.emit(changedSortInfo);
  }

  private getThumbnail(scan: ScandataModel) {
    if (scan.pointcloudStatus !== PointcloudStatus.Ready) return Thumbnail.Busy;

    const hasPreview = scan.thumbnailUrl?.length ?? 0 > 0;
    if (hasPreview) return Thumbnail.Preview;

    const hasStation = scan.numberOfStations > 0;
    if (hasStation) return Thumbnail.Station;

    return Thumbnail.None;
  }

  private getIcon(scan: ScandataModel) {
    switch (scan.pointcloudStatus) {
      case PointcloudStatus.Failed:
        return { icon: 'alert', color: 'text-red', message: 'Failed' };
      case PointcloudStatus.Ready:
        return { icon: 'check_circle', color: 'text-green', message: 'Ready' };
      case PointcloudStatus.Processing:
        return { icon: 'more_circle', color: 'text-trimble-yellow', message: 'Processing' };
      case PointcloudStatus.Uploading:
        return { icon: 'cloud_upload', color: 'text-gray-4', message: 'Uploading' };
      default:
        return { icon: '', color: 'text-trimble-gray', message: '' };
    }
  }

  private toggleScanSelect(scan: ScandataModel) {
    scan.selected = !scan.selected;
    this.selectionChange.emit([scan]);
  }

  private toggleRangeSelected(scan: ScandataModel, selectedIndex: number) {
    const lastSelectedId = this.lastSelectedId();
    if (isNil(lastSelectedId)) return;

    const dataSource = this.dataSource();

    //mat table does not have a property to get the data as it is currently sorted in the UI
    const data = dataSource.sortData(dataSource.data, this.sort());

    const prevSelectedIndex = data.findIndex((row, index) => {
      return row.scan.id === lastSelectedId ? index : null;
    });

    const startIndex = selectedIndex < prevSelectedIndex ? selectedIndex : prevSelectedIndex;
    const endIndex = selectedIndex > prevSelectedIndex ? selectedIndex : prevSelectedIndex;

    const selection = data.filter((_, index) => index >= startIndex && index <= endIndex);
    if (selectedIndex < prevSelectedIndex) selection.reverse();

    const selected = !scan.selected;
    const changedScans = selection.map((row) => <ScandataModel>{ ...row.scan, selected });

    this.selectionChange.emit(changedScans);
  }

  private toggleSingleSelected(scan: ScandataModel) {
    const dataSource = this.dataSource();
    const currentSelection = dataSource.data.filter((row) => row.scan.selected);
    const inCurrentSelection = currentSelection.find((row) => row.scan === scan);

    currentSelection.forEach((row) => (row.scan.selected = false));

    //unselect scan if it's the only current selection
    if (currentSelection.length === 1 && inCurrentSelection) {
      scan.selected = false;
    } else {
      scan.selected = true;
    }

    this.selectionChange.emit([scan]);
  }

  private sortData(data: RowData[], sort: MatSort) {
    /**
     * We want to ensure sorting on a column with duplicate values will always
     * return the items in the same sort order.
     *
     * To achieve that we need to sort on a second distinct value, in this case id.
     *
     * This method is copied from the material library and modified on the last
     * line to include the second sort.
     *
     * Source:
     * https://github.com/angular/components/blob/main/src/material/table/table-data-source.ts#L173
     */
    const active = sort.active;
    const direction = sort.direction;

    return data.sort((a, b) => {
      let valueA = this.sortingDataAccessor()(a, active);
      let valueB = this.sortingDataAccessor()(b, active);

      // If there are data in the column that can be converted to a number,
      // it must be ensured that the rest of the data
      // is of the same type so as not to order incorrectly.
      const valueAType = typeof valueA;
      const valueBType = typeof valueB;

      if (valueAType !== valueBType) {
        if (valueAType === 'number') {
          valueA += '';
        }
        if (valueBType === 'number') {
          valueB += '';
        }
      }

      // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
      // one value exists while the other doesn't. In this case, existing value should come last.
      // This avoids inconsistent results when comparing values to undefined/null.
      // If neither value exists, return 0 (equal).
      let comparatorResult = 0;
      if (valueA != null && valueB != null) {
        // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
        if (valueA > valueB) {
          comparatorResult = 1;
        } else if (valueA < valueB) {
          comparatorResult = -1;
        }
      } else if (valueA != null) {
        comparatorResult = 1;
      } else if (valueB != null) {
        comparatorResult = -1;
      }

      const distinctSort: keyof ScandataModel = 'id';
      let distinctValueA = this.sortingDataAccessor()(a, distinctSort).toString();
      let distinctValueB = this.sortingDataAccessor()(b, distinctSort).toString();

      return (
        comparatorResult * (direction == 'asc' ? 1 : -1) ||
        distinctValueA.localeCompare(distinctValueB)
      );
    });
  }

  private sortingDataAccessor(): (rowData: RowData, sortHeaderId: string) => string | number {
    return (rowData: RowData, sortHeaderId: string): string | number => {
      const value: string = (rowData.scan as never)[sortHeaderId];
      return isNumeric(value) ? Number(value) : isNil(value) ? '' : value.toLowerCase();
    };
  }

  private createSortInfoEffect() {
    effect(() => {
      const sortInfo = this.sortInfo();
      const sort = this.sort();

      if (sort.active === sortInfo.sortBy && sort.direction === sortInfo.sortDirection) return;

      sort.sort({
        id: sortInfo.sortBy,
        start: sortInfo.sortDirection,
        disableClear: true,
      });
    });
  }
}

const isEventFromModusCheckbox = (value: Event): boolean => {
  const src = value.target;
  return isDefined(src) && 'tagName' in src && src.tagName === 'MODUS-CHECKBOX';
};
