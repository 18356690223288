import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transferSpeed',
  standalone: true,
})
export class TransferSpeedPipe implements PipeTransform {
  transform(bytesPerSecond: number, decimalPlaces = 2): string {
    if (Number.isFinite(bytesPerSecond) && bytesPerSecond >= 0 && decimalPlaces >= 0) {
      const suffixes = ['bps', 'Kbps', 'Mbps', 'Gbps'];
      const bitsPerSecond = bytesPerSecond * 8;

      for (let i = 0; i < suffixes.length; i++) {
        if (bitsPerSecond < 1000 ** (i + 1) || i === suffixes.length - 1) {
          return (
            (bitsPerSecond / 1000 ** i).toFixed(i === 0 ? 0 : decimalPlaces) + ' ' + suffixes[i]
          );
        }
      }
    }
    return '';
  }
}
