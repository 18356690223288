import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { combineLatest, filter, of, switchMap } from 'rxjs';
import { ScandataState } from '../scandata/scandata.state';
import { ClassificationEditComponent } from './classification-edit/classification-edit.component';
import { DownloadStatusComponent } from './download/download-status/download-status.component';
import { ListFiltersComponent } from './list-filters/list-filters.component';
import { SetView } from './options-panel.actions';
import { OptionsPanelState, OptionsPanelView } from './options-panel.state';
import { SelectedDetailsComponent } from './selected-details/selected-details.component';

@UntilDestroy()
@Component({
  selector: 'sd-options-panel',
  standalone: true,
  imports: [
    CommonModule,
    ListFiltersComponent,
    DownloadStatusComponent,
    SelectedDetailsComponent,
    ClassificationEditComponent,
  ],
  templateUrl: './options-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsPanelComponent {
  view = OptionsPanelView;
  optionsPanelView = this.store.selectSignal(OptionsPanelState.view);

  constructor(private store: Store) {
    this.subscribeToSelectionChanges();
    this.subscribeToViewChanges();
  }

  private subscribeToSelectionChanges() {
    this.store
      .select(ScandataState.selected)
      .pipe(
        filter((selected) => selected.length > 0),
        switchMap(() => this.store.dispatch(new SetView(OptionsPanelView.SelectedDetails))),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private subscribeToViewChanges() {
    combineLatest([
      this.store.select(OptionsPanelState.view),
      this.store.select(ScandataState.selected),
    ])
      .pipe(
        switchMap(([view, { length: selectedCount }]) => {
          if (selectedCount > 0 && view === OptionsPanelView.None)
            return this.store.dispatch(new SetView(OptionsPanelView.SelectedDetails));

          if (selectedCount === 0 && view === OptionsPanelView.SelectedDetails)
            return this.store.dispatch(new SetView(OptionsPanelView.None));

          return of(void 0);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
