import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { EMPTY, Observable, catchError, exhaustMap, filter, from, map, of, switchMap } from 'rxjs';
import { ConnectFile, ICustomFileActionButton } from 'trimble-connect-workspace-api';
import { LicenseService } from '../license/license.service';
import { LicenseState } from '../license/license.state';
import { Logger, injectLogger } from '../logging/logger';
import { ProjectQuotaService } from '../quota/project-quota.service';
import { SelectOnly } from '../scandata/scandata.actions';
import { PointcloudAPIStatus, ScandataModel } from '../scandata/scandata.models';
import { Role } from '../user/user.models';
import { UserState } from '../user/user.state';
import { IngestionSource } from '../utils/client-identification-headers';
import { ConnectIngestionService } from './connect-ingestion.service';
import { ConnectService } from './connect.service';
import { ConnectFileSelectEvent } from './models/connect-file-select-event';
import { ConnectPanelStatus } from './models/connect-panel-status';

@Injectable({
  providedIn: 'root',
})
export class ConnectPanelService {
  private logger = injectLogger(Logger, 'ConnectPanelService');

  constructor(
    private connectService: ConnectService,
    private ingestionService: ConnectIngestionService,
    private licenseService: LicenseService,
    private projectQuotaService: ProjectQuotaService,
    private store: Store,
  ) {}

  public subscribeToConnectEvents() {
    this.subscribeConnectFileSelect();
    this.subscribeConnectButtonClick();
  }

  private subscribeConnectFileSelect() {
    this.setupFileEvent('extension.fileSelected')
      .pipe(
        switchMap(({ file }) => this.checkLicense(file)),
        switchMap((file) => this.getScan(file)),
        switchMap(({ file, scan }) =>
          isNil(scan)
            ? of(file)
            : this.setupPanelForScan(file.id, scan as ScandataModel).pipe(switchMap(() => EMPTY)),
        ),
        switchMap((file) => this.checkAdmin(file)),
        switchMap((file) => this.checkFileSize(file)),
        switchMap((file) => this.setupPanel(file.id, ConnectPanelStatus.ReadyToTile)),
      )
      .subscribe();
  }

  private subscribeConnectButtonClick() {
    this.subscribeConnectButtonViewClick();
    this.subscribeConnectButtonTileClick();
    this.subscribeConnectButtonRefreshClick();
  }

  private subscribeConnectButtonViewClick() {
    this.setupFileEvent('extension.fileViewClicked')
      .pipe(
        filterStatus(ConnectPanelStatus.Viewing),
        switchMap((fileEvent) => this.getIngestion(fileEvent.file)),
        switchMap((ingestion) => this.store.dispatch(new SelectOnly([ingestion.scan.id]))),
      )
      .subscribe(() => this.connectService.goTo3dExtension());
  }

  private subscribeConnectButtonTileClick() {
    this.setupFileEvent('extension.fileViewClicked')
      .pipe(
        filterStatus(ConnectPanelStatus.Tiling, ConnectPanelStatus.TilingError),
        switchMap(({ file }) => this.checkQuota(file)),
        exhaustMap((file) =>
          this.getFileDownloadUrl(file).pipe(
            switchMap(({ file, downloadUrl }) => this.createIngestion(file, downloadUrl)),
            switchMap((file) => this.setupPanel(file.id, ConnectPanelStatus.ReadyToRefresh)),
          ),
        ),
      )
      .subscribe();
  }

  private subscribeConnectButtonRefreshClick() {
    this.setupFileEvent('extension.fileViewClicked')
      .pipe(
        filterStatus(ConnectPanelStatus.Refreshing),
        switchMap((fileEvent) => this.getIngestion(fileEvent.file)),
        switchMap((ingestion) => this.setupPanelForScan(ingestion.file.id, ingestion.scan)),
      )
      .subscribe();
  }

  private setupFileEvent(eventId: string) {
    return this.observeConnectEvents(eventId).pipe(
      map((workspaceEvent) => workspaceEvent.data as ConnectFileSelectEvent),
      filter((fileEvent) => fileEvent.source.startsWith('explorer')),
      switchMap((fileEvent) =>
        this.setupPanel(fileEvent.file.id, ConnectPanelStatus.CheckingStatus).pipe(
          map(() => fileEvent),
        ),
      ),
    );
  }

  private observeConnectEvents(eventId: string) {
    return from(this.connectService.getWorkspace()).pipe(
      switchMap((workspace) => workspace.event$),
      filter((workspaceEvent) => workspaceEvent.id === eventId),
    );
  }

  private getCustomFileActionButton(status: ConnectPanelStatus): ICustomFileActionButton {
    switch (status) {
      case ConnectPanelStatus.CheckingStatus:
      case ConnectPanelStatus.Refreshing:
        return {
          button: {
            label: 'Loading...',
            disabled: true,
          },
          message: {
            text: 'Checking file status',
            type: 'info',
          },
        };
      case ConnectPanelStatus.FileSizeExceeded:
        return {
          button: {
            label: 'Tile',
            disabled: true,
          },
          message: {
            text: `Files larger than 50GB are not supported.
              Please consider using other tools, such as TBC.`,
            type: 'warning',
          },
        };
      case ConnectPanelStatus.IngestionError:
        return {
          button: {
            label: 'Refresh',
            onClick: ConnectPanelStatus.Refreshing,
          },
          message: {
            text: 'Ingestion error.\nClick refresh to update status.',
            type: 'warning',
          },
        };
      case ConnectPanelStatus.NotAdmin:
        return {
          button: {
            label: 'Tile',
            disabled: true,
          },
          message: {
            text: `Only admin users can tile this file.`,
            type: 'warning',
          },
        };
      case ConnectPanelStatus.NoActiveLicense:
        return {
          button: {
            label: 'Tile',
            disabled: true,
          },
          message: {
            text: 'Could not find an active license for this project.',
            type: 'warning',
          },
        };
      case ConnectPanelStatus.QuotaExceeded:
        return {
          button: {
            label: 'Tile',
            disabled: true,
          },
          message: {
            text: `Quota will be exceeded. Looks like you have run out of space.
              Clean up your data by deleting or archiving,
              alternatively, contact your license administrator to upgrade.`,
            type: 'warning',
          },
        };
      case ConnectPanelStatus.ReadyToRefresh:
        return {
          button: {
            label: 'Refresh',
            onClick: ConnectPanelStatus.Refreshing,
          },
          message: {
            text: 'File tiling is busy processing.\nClick refresh to update status.',
            type: 'warning',
          },
        };
      case ConnectPanelStatus.ReadyToTile:
        return {
          button: {
            label: 'Tile',
            onClick: ConnectPanelStatus.Tiling,
          },
          message: {
            text: 'This point cloud is not tiled and cannot be viewed right now, click on button above to get this process started.',
            type: 'info',
          },
        };
      case ConnectPanelStatus.ReadyToView:
        return {
          button: {
            label: 'View',
            onClick: ConnectPanelStatus.Viewing,
          },
        };
      case ConnectPanelStatus.Tiling:
        return {
          button: {
            label: 'Loading...',
            disabled: true,
          },
          message: {
            text: 'Started tiling process',
            type: 'warning',
          },
        };
      case ConnectPanelStatus.TilingError:
        return {
          button: {
            label: 'Tile',
            onClick: ConnectPanelStatus.TilingError,
          },
          message: {
            text: 'Tiling failed.  Please try again.',
            type: 'error',
          },
        };
      case ConnectPanelStatus.Viewing:
        return {
          button: {
            label: 'Loading...',
            disabled: true,
          },
          message: {
            text: 'Opening Connect 3D',
            type: 'info',
          },
        };
    }
  }

  private getLicenses(file: ConnectFile) {
    return this.licenseService
      .getLicenses()
      .pipe(
        catchError(() =>
          this.setupPanel(file.id, ConnectPanelStatus.NoActiveLicense).pipe(switchMap(() => EMPTY)),
        ),
      );
  }

  private getIngestion(file: ConnectFile) {
    return this.ingestionService
      .getIngestion(file)
      .pipe(catchError((err) => this.handleError(file, err)));
  }

  private getScan(file: ConnectFile) {
    return this.ingestionService.getScan(file).pipe(
      map((scan) => ({ file, scan })),
      catchError((err) => this.handleError(file, err)),
    );
  }

  private getFileDownloadUrl(file: ConnectFile) {
    return this.connectService.getFileDownloadUrl(file).pipe(
      map((download) => ({ file, downloadUrl: download.url })),
      catchError((err) => this.handleError(file, err)),
    );
  }

  private createIngestion(file: ConnectFile, downloadUrl: string) {
    return this.ingestionService
      .createIngestion(file, downloadUrl, IngestionSource.ConnectBrowser)
      .pipe(
        map(() => file),
        catchError((err) => this.handleError(file, err)),
      );
  }

  private checkAdmin(file: ConnectFile) {
    return this.store
      .selectOnce(UserState.userRole)
      .pipe(
        switchMap((role) =>
          role === Role.Admin
            ? of(file)
            : this.setupPanel(file.id, ConnectPanelStatus.NotAdmin).pipe(switchMap(() => EMPTY)),
        ),
      );
  }

  private checkFileSize(file: ConnectFile) {
    return (file.size ?? 0) <= 50 * 1024 ** 3
      ? of(file)
      : this.setupPanel(file.id, ConnectPanelStatus.FileSizeExceeded).pipe(switchMap(() => EMPTY));
  }

  private checkLicense(file: ConnectFile) {
    return this.store.selectOnce(LicenseState.licenses).pipe(
      switchMap((licenses) =>
        isDefined(licenses)
          ? this.store.selectOnce(LicenseState.hasActiveLicense)
          : this.getLicenses(file),
      ),
      switchMap((hasActiveLicense) =>
        hasActiveLicense
          ? of(file)
          : this.setupPanel(file.id, ConnectPanelStatus.NoActiveLicense).pipe(
              switchMap(() => EMPTY),
            ),
      ),
    );
  }

  private checkQuota(file: ConnectFile) {
    return this.projectQuotaService
      .quotaExceeded(file.size ?? 0)
      .pipe(
        switchMap((quotaExceeded) =>
          quotaExceeded
            ? this.setupPanel(file.id, ConnectPanelStatus.QuotaExceeded).pipe(
                switchMap(() => EMPTY),
              )
            : of(file),
        ),
      );
  }

  private setupPanel(connectFileId: string, status: ConnectPanelStatus, thumbnailUrl?: string) {
    return from(this.connectService.getWorkspace()).pipe(
      switchMap((workspace) =>
        from(
          workspace.api.ui.addCustomFileAction([
            {
              fileId: connectFileId,
              actionButton: this.getCustomFileActionButton(status),
              thumbnailUrl,
            },
          ]),
        ),
      ),
    );
  }

  private setupPanelForScan(connectFileId: string, scan: ScandataModel) {
    switch (scan.status) {
      case PointcloudAPIStatus.Initializing:
      case PointcloudAPIStatus.InProgress:
        return this.setupPanel(connectFileId, ConnectPanelStatus.ReadyToRefresh, scan.thumbnailUrl);
      case PointcloudAPIStatus.Ready:
        return this.setupPanel(connectFileId, ConnectPanelStatus.ReadyToView, scan.thumbnailUrl);
      case PointcloudAPIStatus.Failed:
        return this.setupPanel(connectFileId, ConnectPanelStatus.TilingError, scan.thumbnailUrl);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError(file: ConnectFile, err: any) {
    this.logger.error(`Ingesting error (${file.name})`, {}, err);
    return this.setupPanel(file.id, ConnectPanelStatus.IngestionError).pipe(switchMap(() => EMPTY));
  }
}

function filterStatus(...statuses: ConnectPanelStatus[]) {
  return (source: Observable<ConnectFileSelectEvent>) =>
    source.pipe(
      filter((event) => {
        const status = getPanelStatusFromSource(event.source);
        return statuses.findIndex((x) => x === status) > -1;
      }),
    );
}

function getPanelStatusFromSource(source: string) {
  // source includes the onClick string supplied to setCustomActionButton
  // e.g. explorer.tile
  const replacedSource =
    source === 'explorer' ? ConnectPanelStatus.CheckingStatus : source.replace('explorer.', '');
  return replacedSource as ConnectPanelStatus;
}
