import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { take } from 'rxjs';
import { FitToView } from '../../connect-3d-ext/host-3d.actions';
import { Host3dService } from '../../connect-3d-ext/host-3d.service';
import {
  ScandataTreeIconComponent,
  TreeIcon,
} from '../../scandata-tree/scandata-tree-icon/scandata-tree-icon.component';
import {
  PointcloudStatus,
  ScandataDisplayStatus,
  ScandataLoadStatus,
  ScandataModel,
} from '../../scandata/scandata.models';
import { ScandataState } from '../../scandata/scandata.state';
import { Station, StationDisplayStatus } from '../../station/station.models';
import { StationState } from '../../station/station.state';
import { Scan3dStyle } from '../models/scan-3d-style';
import { Scan3dDetailComponent } from '../scan-3d-detail/scan-3d-detail.component';
import { Scan3dSettingsComponent } from '../scan-3d-settings/scan-3d-settings.component';
import { Scan3dStationDetailComponent } from '../scan-3d-station-detail/scan-3d-station-detail.component';
import { Scan3dOptionsPanelView } from './scan-3d-options-panel.models';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-options-panel',
  standalone: true,
  imports: [
    CommonModule,
    Scan3dDetailComponent,
    Scan3dStationDetailComponent,
    Scan3dSettingsComponent,
    ScandataTreeIconComponent,
    ModusIconModule,
    ModusTooltipModule,
    ModusButtonModule,
    MatExpansionModule,
  ],
  templateUrl: './scan-3d-options-panel.component.html',
  styles: [
    `
      :host {
        transition: height 200ms ease-in-out;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dOptionsPanelComponent {
  optionsPanelView = input.required<Scan3dOptionsPanelView>();

  globalStyle = input<Scan3dStyle>();
  selectedScan = input<ScandataModel>();
  selectedScan3dStyle = input<Scan3dStyle>();
  selectedStation = input<Station>();
  quotaExceeded = input(false);

  toggleExpandClick = output<Scan3dOptionsPanelView>();

  fitToViewClick = output<ScandataModel>();
  exitStationClick = output();
  selectStationClick = output<Station>();

  onGlobalStyleChange = output<Scan3dStyle>();
  onStyleChange = output<Scan3dStyle>();

  view = Scan3dOptionsPanelView;

  expandSettingsPanel = computed(() => this.optionsPanelView() === Scan3dOptionsPanelView.Settings);
  expandScanPanel = computed(() => this.optionsPanelView() === Scan3dOptionsPanelView.Scan);
  expandStationPanel = computed(() => this.optionsPanelView() === Scan3dOptionsPanelView.Station);

  currentStation = this.store.selectSignal(StationState.currentStation);
  disableStationExit = computed(() => {
    return this.currentStation()?.displayStatus !== StationDisplayStatus.Displayed;
  });

  scans = this.store.selectSignal(ScandataState.scandata);
  stations = computed(() => {
    const currentStation = this.currentStation();
    if (isNil(currentStation)) return [];

    const scans = this.scans();
    const stationScan = scans.find((scan) =>
      scan.stations?.some((station) => station.id === currentStation.station.id),
    );

    return stationScan?.stations ?? [];
  });
  disableStationPaging = computed(() => this.stations().length <= 1);

  constructor(
    private host3dService: Host3dService,
    private store: Store,
  ) {}

  getModelIcon(model: ScandataModel): TreeIcon {
    if (this.modelNotReady(model) || this.quotaExceeded()) return TreeIcon.Unavailable;

    if (model.pointcloudStatus === PointcloudStatus.Failed) {
      return TreeIcon.Error;
    }

    if (
      model.loadStatus === ScandataLoadStatus.LoadingError ||
      model.displayStatus === ScandataDisplayStatus.DisplayError
    ) {
      return TreeIcon.Error;
    }

    if (
      model.loadStatus === ScandataLoadStatus.Loading ||
      model.displayStatus === ScandataDisplayStatus.AwaitingDisplay
    ) {
      return TreeIcon.Loading;
    }

    if (!model.showInScene) {
      return TreeIcon.Off;
    }

    return TreeIcon.On;
  }

  loadModelDisabled(model: ScandataModel) {
    return (
      model.pointCount === 0 ||
      model.pointcloudStatus !== PointcloudStatus.Ready ||
      this.quotaExceeded()
    );
  }

  modelNotReady(model: ScandataModel) {
    return (
      model.pointcloudStatus === PointcloudStatus.Uploading ||
      model.pointcloudStatus === PointcloudStatus.Processing
    );
  }

  modelFitToViewClicked(model: ScandataModel) {
    this.store.dispatch(new FitToView([model]));
  }

  modelIconClick(scan: ScandataModel) {
    const show = !scan.showInScene;
    if (show) {
      this.host3dService.showScan(scan).pipe(take(1)).subscribe();
      //this.scanSelected.emit(scan);
    } else {
      this.host3dService.hideScan(scan).pipe(take(1)).subscribe();
      //this.scanSelected.emit(null);
    }
  }

  toggleView(view: Scan3dOptionsPanelView, event: MouseEvent) {
    // cancel the default mat-expansion-panel behaviour
    event.preventDefault();
    event.stopPropagation();

    if (view === Scan3dOptionsPanelView.Scan && isNil(this.selectedScan())) return;
    if (view === Scan3dOptionsPanelView.Station && isNil(this.selectedStation())) return;

    this.toggleExpandClick.emit(view);
  }

  cancelClick(event: MouseEvent) {
    event.stopPropagation();
  }

  fitToViewDisabled() {
    return (
      this.selectedScan()?.displayStatus !== ScandataDisplayStatus.Displayed ||
      isDefined(this.selectedStation())
    );
  }

  selectPrevStation() {
    this.selectStation(-1);
  }

  selectNextStation() {
    this.selectStation(1);
  }

  private selectStation(direction: number) {
    const stations = this.stations();
    const currentStation = this.currentStation();
    const currentIndex = stations.findIndex((station) => station.id === currentStation?.station.id);

    const selectIndex =
      direction > 0
        ? currentIndex === stations.length - 1
          ? 0
          : currentIndex + 1
        : currentIndex === 0
          ? stations.length - 1
          : currentIndex - 1;

    const station = stations[selectIndex];
    this.selectStationClick.emit(station);
  }
}
