import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  NEVER,
  catchError,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  from,
  interval,
  map,
  of,
  switchMap,
} from 'rxjs';
import {
  ConnectFile,
  EventId,
  EventToArgMap,
  Viewer3DEmbedProperties,
  WorkspaceAPI,
  connect,
} from 'trimble-connect-workspace-api';
import { SetProject } from '../app-state/app.actions';
import { AppRoute } from '../app.routes';
import { ClearAuth, SetConnectToken } from '../auth/auth.actions';
import { FitToView, SetHost3dScandata } from '../connect-3d-ext/host-3d.actions';
import { Logger, injectLogger } from '../logging/logger';
import { ScandataState } from '../scandata/scandata.state';
import { ConnectWorkspace } from './connect.models';
import { GET_CONNECT_REGION_URL } from './get-connect-region-url';
import { ConnectFileDownload } from './models/connect-file-download';

@Injectable({
  providedIn: 'root',
})
export class ConnectService {
  private readonly logger = injectLogger(Logger, 'ConnectService');
  private readonly getConnectRegionUrl$ = inject(GET_CONNECT_REGION_URL);
  private _workspace!: ConnectWorkspace;

  constructor(
    private http: HttpClient,
    private store: Store,
    private actions$: Actions,
    private router: Router,
  ) {}

  async getWorkspace(
    target?: Window | HTMLIFrameElement,
    timeout: number = 1000 * 30,
  ): Promise<ConnectWorkspace> {
    if (isNil(this._workspace) && isDefined(target)) {
      await this.initWorkspace(target, timeout);
    }
    return this._workspace;
  }

  goTo3dExtension() {
    this.store
      .selectOnce(ScandataState.scandata)
      .pipe(switchMap((scandata) => this.store.dispatch(new SetHost3dScandata(scandata))))
      .subscribe(async () => await this._workspace.api.extension.goTo('3dviewer'));
  }

  async goToConnect3dViewer(params?: Viewer3DEmbedProperties) {
    return await this._workspace.api.extension.goTo('3dviewer', params);
  }

  async enableClosingPrompt(prompt?: string) {
    await this._workspace.api.extension.preventClosing(prompt);
  }

  async disableClosingPrompt() {
    await this._workspace.api.extension.preventClosing();
  }

  async goToProjectSettings() {
    return await this._workspace.api.extension.goTo('settings-details');
  }

  async goToProjectExtensions() {
    return await this._workspace.api.extension.goTo('settings-extensions');
  }

  getFileDownloadUrl(file: ConnectFile) {
    const versionQuery = file.versionId ? `?versionId=${file.versionId}` : '';
    return this.getConnectRegionUrl$(`files/fs/${file.id}/downloadurl${versionQuery}`).pipe(
      switchMap((url) => this.http.get<ConnectFileDownload>(url)),
    );
  }

  private async initWorkspace(target: Window | HTMLIFrameElement, timeout?: number | undefined) {
    const api = await this.createWorkspace(target, timeout);

    if (isWorkspaceAPI(api)) {
      this._workspace = new ConnectWorkspace(api);

      await this.getConnectProject();
      this.subscribeToFitToView();

      this.subscribeToConnectTokenEvent();
      this.subscribeToConnectTokenRequest();
      await this.getConnectToken();
    }
  }

  private async createWorkspace(
    target: Window | HTMLIFrameElement,
    timeout?: number | undefined,
  ): Promise<WorkspaceAPI> {
    const onEvent = this.onWorkspaceEvent.bind(this);
    return await connect(target, onEvent, timeout);
  }

  private onWorkspaceEvent<T extends EventId, U extends EventToArgMap[T]>(eventId: T, arg: U) {
    const data = arg?.data;
    const action = arg?.action;
    const event = { id: eventId, data, action };
    this._workspace?.['_event$'].next(event);
  }

  private async getConnectProject() {
    const project = await this._workspace.api.project.getProject();
    this.store.dispatch(new SetProject(project));
    return project;
  }

  private subscribeToConnectTokenEvent() {
    /**
     * NOTE:
     * At present (2 Sep 2024), this event only fires after:
     * 1) The user clicks Deny|Allow from the Request Permissions dialog.
     * 2) Connect refreshed the token and you've previously called requestPermission('accessToken')
     *
     * Ideally it should also fire after the user clicks 'Reset Authorization' for the extension.
     */
    this._workspace.event$
      .pipe(
        filter((event) => event.id === 'extension.accessToken'),
        map((event) => event.data as string),
        switchMap((token) => {
          return from(this.handleConnectToken(token)).pipe(
            catchError((err) =>
              of(this.logger.error(`Connect token event handle token error`, {}, err)),
            ),
          );
        }),
      )
      .subscribe();
  }

  private subscribeToConnectTokenRequest() {
    /**
     * The call to workspace requestPermission('accesstoken') will:
     * 1) Cause the workspace to start emitting 'extension.accessToken' events.
     * 2) Show the prompt to grant extension permission if the token value equals 'Pending'.
     *
     * We don't receive any event when the user clicks 'Reset authorization' for the extension.
     * This could result in the extension having an expired token, if the user has
     * previously granted access & then resets the authorization.
     *
     * To handle this scenario we will periodically request the access token,
     * in addition to doing the immediate initial request.
     */

    interval(5000)
      .pipe(
        switchMap(() => {
          return from(this._workspace.api.extension.requestPermission('accesstoken')).pipe(
            catchError((err) => {
              this.logger.error(`Connect token request permission error`, {}, err);
              return NEVER;
            }),
          );
        }),
        distinctUntilChanged(),
        switchMap((token) => {
          return from(this.handleConnectToken(token)).pipe(
            catchError((err) =>
              of(this.logger.error(`Connect token request handle token error`, {}, err)),
            ),
          );
        }),
      )
      .subscribe();
  }

  private async getConnectToken() {
    try {
      var token = await this._workspace.api.extension.requestPermission('accesstoken');
      await this.handleConnectToken(token);
    } catch {
      this.logger.error(`Get Connect token error`);
      await firstValueFrom(this.store.dispatch(ClearAuth));
    }
  }

  private async handleConnectToken(token: string) {
    switch (token.toLowerCase()) {
      case 'denied':
        return await this.handleDeniedToken();

      case 'pending':
        return await this.handlePendingToken();

      default:
        return await this.setToken(token);
    }
  }

  private async handleDeniedToken() {
    await this._workspace.api.extension.setStatusMessage('Requires permission');
    await firstValueFrom(this.store.dispatch(new ClearAuth()));

    //get the path, trimming leading slash
    const path = location.pathname.replace(/^\//, '');

    if (!path.includes(AppRoute.ConnectPermissionDenied)) {
      await this.router.navigate([AppRoute.ConnectPermissionDenied], {
        queryParams: { returnPath: path },
      });
    }
  }

  private async handlePendingToken() {
    await this._workspace.api.extension.setStatusMessage('');
    await firstValueFrom(this.store.dispatch(new ClearAuth()));
  }

  private async setToken(token: string) {
    await this._workspace.api.extension.setStatusMessage('');
    await firstValueFrom(this.store.dispatch(new SetConnectToken(token)));
  }

  private subscribeToFitToView() {
    this.actions$
      .pipe(
        ofActionDispatched(FitToView),
        map((action) => action.models.map((x) => ({ modelId: x.web3dId, objectRuntimeIds: [1] }))),
      )
      .subscribe((modelObjectIds) => this._workspace.api.viewer.setCamera({ modelObjectIds }));
  }
}

export function isWorkspaceAPI(arg: unknown): arg is WorkspaceAPI {
  return (arg as WorkspaceAPI).extension !== undefined;
}
