import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ModusButtonModule, ModusIconModule } from '@trimble-gcs/modus';
import { User } from '../../user/user.models';
import { ProjectSelectComponent } from './project-select/project-select.component';

@Component({
  selector: 'sd-app-bar',
  standalone: true,
  imports: [
    CommonModule,
    ModusButtonModule,
    ModusIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    ProjectSelectComponent,
  ],
  templateUrl: './app-bar.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBarComponent {
  loggedIn = input(false);
  user = input<User>();

  menuClick = output<MouseEvent>();
  profileClick = output();
  licenceClick = output();
  logoutClick = output();
}
