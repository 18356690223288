import { CommonModule } from '@angular/common';
import { Component, computed } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, switchMap, take } from 'rxjs';
import { AppContext } from './app-state/app.models';
import { AppService } from './app-state/app.service';
import { AppState } from './app-state/app.state';
import { DialogService } from './dialog/dialog.service';
import { AppHostComponent } from './hosts/app-host/app-host.component';
import { ExtHostComponent } from './hosts/ext-host/ext-host.component';
import { LicenseState } from './license/license.state';
import { OutOfQuotaDialogComponent } from './quota/out-of-quota-dialog.component';
import { ProjectQuotaService } from './quota/project-quota.service';

@Component({
  selector: 'sd-root',
  standalone: true,
  imports: [CommonModule, AppHostComponent, ExtHostComponent],
  template: `
    @if (extensionHost()) {
      <sd-ext-host></sd-ext-host>
    } @else {
      <sd-app-host></sd-app-host>
    }
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
      }
    `,
  ],
})
export class AppComponent {
  extensionHost = computed(() => {
    const context = this.store.selectSignal(AppState.context);
    return context() === AppContext.ConnectExtension;
  });

  constructor(
    appService: AppService,
    private store: Store,
    private projectQuotaService: ProjectQuotaService,
    private dialogService: DialogService,
  ) {
    this.store
      .select(LicenseState.hasActiveLicense)
      .pipe(
        filter((hasActiveLicense) => hasActiveLicense),
        take(1),
        switchMap(() => appService.loadAppData()),
        switchMap(() => this.checkProjectQuota()),
      )
      .subscribe();
  }

  private checkProjectQuota() {
    return this.projectQuotaService.getProjectQuota().pipe(
      filter((quota) => quota.quotaUsedByAccount >= quota.quota),
      switchMap(() => {
        return this.dialogService.showComponent(OutOfQuotaDialogComponent, {
          disableClose: true,
        });
      }),
    );
  }
}
