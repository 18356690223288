import { OptionsPanelInfoView, OptionsPanelView } from './options-panel.state';

export class SetView {
  static readonly type = '[OptionsPanelState] SetView';
  constructor(
    public readonly view: OptionsPanelView,
    public readonly withToggle: boolean = false,
  ) {}
}

export class SetInfoView {
  static readonly type = '[OptionsPanelState] SetInfoView';
  constructor(public readonly infoView: OptionsPanelInfoView) {}
}
