import { v4 as uuidv4 } from 'uuid';
import { ScandataModel } from '../scandata/scandata.models';

export const ALLOWED_EXTENSIONS = ['.las', '.laz', '.e57'];

export enum ImportStatus {
  NotStarted = 'NotStarted',
  Busy = 'Busy',
  Completed = 'Completed',
  Error = 'Error',
}

export enum ImportScanStatus {
  Pending = 'Pending',
  CheckQuota = 'Checking quota',
  CreatePointcloud = 'Creating pointcloud',
  CreateFiles = 'Allocating space',
  UploadFiles = 'Uploading',
  Ingest = 'Starting ingestion',
  Completed = 'Completed',
  Error = 'Error',
}

export interface ImportScan {
  id: string;
  name: string;
  files: ImportFile[];
  status: ImportScanStatus;

  scan?: ScandataModel;
  errorMessage?: string;
}

export enum ImportFileSource {
  Upload = 'Upload',
  // connect, google drive, dropbox
}

export enum ImportFileStatus {
  Pending = 'Pending',
  Busy = 'Busy',
  Completed = 'Completed',
  Error = 'Error',
  Skipped = 'Skipped',
}

export interface ImportFile {
  id: string;
  name: string;
  size: number;
  source: ImportFileSource;

  statusMessage?: string;
  status?: ImportFileStatus;
  fileUpload?: {
    file: File;
    uploadUrl?: string;
    progress?: number;
    startTime?: Date;
    transferred?: number;
  };
}

export function createImportFile(file: File): ImportFile {
  return {
    id: uuidv4(),
    name: file.name,
    size: file.size,
    source: ImportFileSource.Upload,
    fileUpload: { file },
  };
}
