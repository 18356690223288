import { timeStampNow } from '../../tools/utils/timeUtils';
import { normalizeUrl } from '../../tools/utils/urlPolyfill';
import { generateUUID } from '../../tools/utils/stringUtils';
import { INTAKE_SITE_US1, INTAKE_SITE_FED_STAGING, PCI_INTAKE_HOST_US1 } from './intakeSites';
export function createEndpointBuilder(initConfiguration, trackType, configurationTags) {
  var buildUrlWithParameters = createEndpointUrlWithParametersBuilder(initConfiguration, trackType);
  return {
    build: function (api, payload) {
      var parameters = buildEndpointParameters(initConfiguration, trackType, configurationTags, api, payload);
      return buildUrlWithParameters(parameters);
    },
    urlPrefix: buildUrlWithParameters(''),
    trackType: trackType
  };
}
/**
 * Create a function used to build a full endpoint url from provided parameters. The goal of this
 * function is to pre-compute some parts of the URL to avoid re-computing everything on every
 * request, as only parameters are changing.
 */
function createEndpointUrlWithParametersBuilder(initConfiguration, trackType) {
  var path = "/api/v2/".concat(trackType);
  var proxy = initConfiguration.proxy;
  if (typeof proxy === 'string') {
    var normalizedProxyUrl_1 = normalizeUrl(proxy);
    return function (parameters) {
      return "".concat(normalizedProxyUrl_1, "?ddforward=").concat(encodeURIComponent("".concat(path, "?").concat(parameters)));
    };
  }
  if (typeof proxy === 'function') {
    return function (parameters) {
      return proxy({
        path: path,
        parameters: parameters
      });
    };
  }
  var host = buildEndpointHost(trackType, initConfiguration);
  return function (parameters) {
    return "https://".concat(host).concat(path, "?").concat(parameters);
  };
}
function buildEndpointHost(trackType, initConfiguration) {
  var _a = initConfiguration.site,
    site = _a === void 0 ? INTAKE_SITE_US1 : _a,
    internalAnalyticsSubdomain = initConfiguration.internalAnalyticsSubdomain;
  if (trackType === 'logs' && initConfiguration.usePciIntake && site === INTAKE_SITE_US1) {
    return PCI_INTAKE_HOST_US1;
  }
  if (internalAnalyticsSubdomain && site === INTAKE_SITE_US1) {
    return "".concat(internalAnalyticsSubdomain, ".").concat(INTAKE_SITE_US1);
  }
  if (site === INTAKE_SITE_FED_STAGING) {
    return "http-intake.logs.".concat(site);
  }
  var domainParts = site.split('.');
  var extension = domainParts.pop();
  return "browser-intake-".concat(domainParts.join('-'), ".").concat(extension);
}
/**
 * Build parameters to be used for an intake request. Parameters should be re-built for each
 * request, as they change randomly.
 */
function buildEndpointParameters(_a, trackType, configurationTags, api, _b) {
  var clientToken = _a.clientToken,
    internalAnalyticsSubdomain = _a.internalAnalyticsSubdomain;
  var retry = _b.retry,
    encoding = _b.encoding;
  var tags = ["sdk_version:".concat("5.23.3"), "api:".concat(api)].concat(configurationTags);
  if (retry) {
    tags.push("retry_count:".concat(retry.count), "retry_after:".concat(retry.lastFailureStatus));
  }
  var parameters = ['ddsource=browser', "ddtags=".concat(encodeURIComponent(tags.join(','))), "dd-api-key=".concat(clientToken), "dd-evp-origin-version=".concat(encodeURIComponent("5.23.3")), 'dd-evp-origin=browser', "dd-request-id=".concat(generateUUID())];
  if (encoding) {
    parameters.push("dd-evp-encoding=".concat(encoding));
  }
  if (trackType === 'rum') {
    parameters.push("batch_time=".concat(timeStampNow()));
  }
  if (internalAnalyticsSubdomain) {
    parameters.reverse();
  }
  return parameters.join('&');
}
