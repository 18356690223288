<div class="flex flex-col h-full overflow-hidden">
  <sd-scan-3d-list
    [optionsPanelView]="optionsPanelView()"
    [quotaExceeded]="quotaExceeded()"
    [selectedScan]="selectedScan()"
    [selectedStation]="selectedStation()"
    (scanSelected)="onScanSelected($event)"
    (stationSelected)="onStationSelected($event)"
    class="flex flex-col flex-1 overflow-hidden"
  ></sd-scan-3d-list>

  <!-- TODO: refactor ngClass -->
  <sd-scan-3d-options-panel
    [optionsPanelView]="optionsPanelView()"
    [globalStyle]="globalStyle()"
    [selectedScan]="selectedScan()"
    [selectedScan3dStyle]="selectedScan3dStyle()"
    [selectedStation]="selectedStation()"
    [quotaExceeded]="quotaExceeded()"
    (onGlobalStyleChange)="onGlobalStyleChange($event)"
    (onStyleChange)="onStyleChange($event)"
    (toggleExpandClick)="onToggleExpandClick($event)"
    (fitToViewClick)="onFitToViewClick($event)"
    (exitStationClick)="onExitStationClick()"
    (selectStationClick)="onSelectStationClick($event)"
    [ngClass]="
      optionsPanelExpanded()
        ? 'h-1/2'
        : this.selectedScan() && this.selectedStation()
          ? 'h-24'
          : this.selectedScan() && !this.selectedStation()
            ? 'h-16'
            : !this.selectedScan() && this.selectedStation()
              ? 'h-16'
              : 'h-8'
    "
    class="flex flex-col overflow-hidden max-h-[50%]"
  ></sd-scan-3d-options-panel>
</div>
