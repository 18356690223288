import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { GET_CONNECT_REGION_URL } from './get-connect-region-url';
import { ConnectLicence } from './models/connect-license';

@Injectable({
  providedIn: 'root',
})
export class ConnectProjectService {
  private readonly getConnectRegionUrl$ = inject(GET_CONNECT_REGION_URL);

  constructor(private http: HttpClient) {}

  getProjectLicence(project: ConnectProject) {
    return this.getConnectRegionUrl$(`projects/${project.id}/license`).pipe(
      switchMap((url) => this.http.get<ConnectLicence>(url)),
    );
  }

  getPrimaryAccount(project: ConnectProject) {
    return this.getProjectLicence(project).pipe(
      map((licence) => licence.accounts.find((acc) => acc.primary) ?? licence.accounts[0]),
    );
  }
}
