import { ImportFile, ImportScan } from './import.models';

export class SetImportScans {
  static readonly type = '[ImportState] SetImportScans';
  constructor(public importScans: ImportScan[]) {}
}

export class PatchImportScan {
  static readonly type = '[ImportState] PatchImportScan';
  constructor(public importScan: ImportScan) {}
}

export class PatchImportFile {
  static readonly type = '[ImportState] PatchImportFile';
  constructor(public importFile: ImportFile) {}
}

export class ClearImportScans {
  static readonly type = '[ImportState] ClearImportScans';
}
