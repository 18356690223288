import { AppSettings } from '../app-state/app.models';

export enum IngestionSource {
  Connect3D = 'Connect 3D',
  ConnectBrowser = 'Connect Browser',
  Import = 'Import',
}

export function GetClientIdentificationHeaders(
  settings: AppSettings,
  source: IngestionSource,
): {
  [header: string]: string;
} {
  return {
    'Trimble-FieldSystems-ClientName': `${settings.title} - ${source}`,
    'Trimble-FieldSystems-ClientVersion': 'latest',
  };
}
