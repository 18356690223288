<div class="flex flex-col justify-end h-full text-sm">
  <!-- Scan -->
  @if (selectedScan(); as selectedScan) {
    <div
      class="group flex flex-col overflow-hidden min-h-8"
      [ngClass]="expandScanPanel() ? 'h-full' : 'h-8'"
      style="transition: height 200ms ease-in-out"
    >
      <div
        (click)="toggleView(view.Scan, $event)"
        class="flex min-h-8 box-content border-t border-b border-gray-1 bg-gray-light h-8"
        [ngClass]="{ 'cursor-pointer hover:bg-gray-0': selectedScan }"
      >
        <div class="flex flex-1 items-center overflow-hidden">
          <md-icon>
            {{ expandScanPanel() ? 'caret_down' : 'caret_right' }}
          </md-icon>
          <div class="flex flex-1 items-center p-1 overflow-hidden">
            <span class="uppercase font-medium">Scan</span>

            :
            <span class="ml-1 overflow-hidden whitespace-nowrap text-ellipsis">{{
              selectedScan.name
            }}</span>
          </div>

          <div class="hidden group-hover:flex" (click)="cancelClick($event)">
            <sd-scandata-tree-icon
              class="flex items-center cursor-default min-w-[28px]"
              [treeIcon]="getModelIcon(selectedScan)"
              [disabled]="loadModelDisabled(selectedScan)"
              (clicked)="modelIconClick(selectedScan)"
            ></sd-scandata-tree-icon>

            <button
              id="3d-scan-fit-to-view"
              class="text-gray-8 mr-1"
              modus-icon-button
              modus-tooltip="Fit to view"
              [disabled]="fitToViewDisabled()"
              (click)="modelFitToViewClicked(selectedScan)"
            >
              <md-icon>expand</md-icon>
            </button>
          </div>
        </div>
      </div>

      <sd-scan-3d-detail
        [currentModel]="selectedScan"
        [scan3dStyle]="selectedScan3dStyle()!"
        (fitToViewClick)="fitToViewClick.emit($event)"
        (styleChange)="onStyleChange.emit($event)"
        class="block h-full overflow-hidden"
      ></sd-scan-3d-detail>
    </div>
  }

  <!-- Station -->
  @if (selectedStation(); as selectedStation) {
    <div
      class="group flex flex-col overflow-hidden min-h-8"
      [ngClass]="expandStationPanel() ? 'h-full' : 'h-8'"
      style="transition: height 200ms ease-in-out"
    >
      <div
        (click)="toggleView(view.Station, $event)"
        class="flex min-h-8 box-content border-t border-b border-gray-1 bg-gray-light h-8"
        [ngClass]="{ 'cursor-pointer hover:bg-gray-0': selectedStation }"
      >
        <div class="flex flex-1 items-center overflow-hidden">
          <md-icon>
            {{ expandStationPanel() ? 'caret_down' : 'caret_right' }}
          </md-icon>
          <div class="flex flex-1 items-center p-1 overflow-hidden">
            <span class="uppercase font-medium">Station</span>

            :
            <span class="ml-1 overflow-hidden whitespace-nowrap text-ellipsis">{{
              selectedStation.name
            }}</span>
          </div>
          <div class="hidden group-hover:flex" (click)="cancelClick($event)">
            <button
              id="3d-scan-station-prev"
              class="text-gray-8 mr-1"
              modus-icon-button
              [disabled]="disableStationPaging()"
              (click)="selectPrevStation()"
            >
              <md-icon> arrow_left </md-icon>
            </button>
            <button
              id="3d-scan-station-exit"
              class="text-gray-8 mr-1"
              modus-icon-button
              [disabled]="disableStationExit()"
              (click)="exitStationClick.emit()"
            >
              <md-icon> cancel_circle </md-icon>
            </button>
            <button
              id="3d-scan-station-next"
              class="text-gray-8 mr-1"
              modus-icon-button
              [disabled]="disableStationPaging()"
              (click)="selectNextStation()"
            >
              <md-icon> arrow_right </md-icon>
            </button>
          </div>
        </div>
      </div>

      <sd-scan-3d-station-detail
        [currentStation]="selectedStation"
        (exitStationClick)="exitStationClick.emit()"
        class="block h-full overflow-hidden"
      ></sd-scan-3d-station-detail>
    </div>
  }

  <!-- Global settings -->
  <div
    class="flex flex-col overflow-hidden min-h-8"
    [ngClass]="expandSettingsPanel() ? 'h-full' : 'h-8'"
    style="transition: height 200ms ease-in-out"
  >
    <div
      (click)="toggleView(view.Settings, $event)"
      class="flex flex-1 items-center cursor-pointer min-h-8 box-content border-t border-b border-gray-1 bg-gray-light hover:bg-gray-0"
    >
      <md-icon>
        {{ expandSettingsPanel() ? 'caret_down' : 'caret_right' }}
      </md-icon>
      <div class="flex items-center p-1 uppercase font-medium">Global Settings</div>
    </div>

    <sd-scan-3d-settings
      [scan3dStyle]="globalStyle()"
      (styleChange)="onGlobalStyleChange.emit($event)"
      class="block h-full overflow-hidden"
    ></sd-scan-3d-settings>
  </div>
</div>
