import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { FeatureFlagService } from '@trimble-gcs/feature-flags';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { FileSizePipe } from '@trimble-gcs/ngx-common';
import { switchMap } from 'rxjs';
import { AppState } from '../../app-state/app.state';
import { ConnectProjectService } from '../../connect/connect-project.service';
import { FeatureFlagKey } from '../../feature-flags/feature-flag-key';
import { LoadingService } from '../../loading/loading.service';
import { PercentagePipe } from '../../pipes/percentage.pipe';
import { ProjectQuota, ProjectQuotaService } from '../../quota/project-quota.service';
import { UsageBarComponent } from './usage-bar/usage-bar.component';

interface ProjectQuotaView extends ProjectQuota {
  quotaUsedByAccountPercent: number;
  quotaUsedByProjectPercent: number;
}

@UntilDestroy()
@Component({
  selector: 'sd-config-storage',
  standalone: true,
  imports: [
    CommonModule,
    UsageBarComponent,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    MatProgressBarModule,
    MatSliderModule,
    PercentagePipe,
    FileSizePipe,
  ],
  templateUrl: './storage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageComponent {
  recycleBinFeatureActive = toSignal(
    this.featureFlagService.hasFeature$('config_cloudStorage_recycleBin'),
    { requireSync: true },
  );

  isLoading = toSignal(this.loadingService.isLoading$(this), { requireSync: true });

  primaryAccount = toSignal(this.getPrimaryAccount());

  projectQuotaView = computed(() => {
    const projectQuota = this.projectQuota();
    return isDefined(projectQuota) ? this.getProjectQuotaView(projectQuota) : undefined;
  });

  private projectQuota = toSignal(this.getProjectQuota());

  constructor(
    private store: Store,
    private loadingService: LoadingService,
    private connectProjectService: ConnectProjectService,
    private projectQuotaService: ProjectQuotaService,
    private featureFlagService: FeatureFlagService<FeatureFlagKey>,
  ) {}

  private getPrimaryAccount() {
    return this.store
      .select(AppState.project)
      .pipe(switchMap((project) => this.connectProjectService.getPrimaryAccount(project)));
  }

  private getProjectQuota() {
    return this.loadingService.loadFrom(this.projectQuotaService.getProjectQuota(), this);
  }

  private getProjectQuotaView(projectQuota: ProjectQuota) {
    const quotaUsedByAccountPercent =
      Math.abs(projectQuota.quota) > 0
        ? (projectQuota.quotaUsedByAccount / projectQuota.quota) * 100
        : 100;

    const quotaUsedByProjectPercent =
      Math.abs(projectQuota.quota) > 0
        ? (projectQuota.quotaUsedByProject / projectQuota.quota) * 100
        : 100;

    return {
      ...projectQuota,
      quotaUsedByAccountPercent,
      quotaUsedByProjectPercent,
    } satisfies ProjectQuotaView;
  }
}
