import { Directive, effect, HostListener, input, output, signal } from '@angular/core';
import { isNil } from '@trimble-gcs/common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[file-drag-drop]',
  standalone: true,
})
export class FileDragDropDirective {
  disabled = input(false);
  filesDropped = output<File[]>();
  dragOver = output<boolean>();

  private currentlyDraggingOver = signal(false);

  constructor() {
    effect(() => {
      this.dragOver.emit(this.currentlyDraggingOver());
    });
  }

  @HostListener('window:dragover', ['$event']) onWindowDragOver(event: DragEvent) {
    if (event.dataTransfer) event.dataTransfer.dropEffect = 'none';
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    if (this.disabled()) return;

    event.preventDefault();
    event.stopPropagation();

    this.currentlyDraggingOver.set(true);
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    if (this.disabled()) return;

    event.preventDefault();
    event.stopPropagation();

    this.currentlyDraggingOver.set(false);
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    if (this.disabled() || isNil(event.dataTransfer)) return;

    event.preventDefault();
    event.stopPropagation();

    this.currentlyDraggingOver.set(false);

    const files = Array.from(event.dataTransfer.files);
    if (files.length > 0) {
      this.filesDropped.emit(files);
    }
  }
}
