<div
  #map
  leaflet
  class="flex flex-col h-full outline-0"
  (leafletMapReady)="setMapInstance($event)"
  (leafletMapZoomEnd)="zoomed($event)"
  (leafletMapMoveEnd)="moved()"
  [leafletOptions]="options"
>
  @let layer = baseLayer();
  @if (layer !== null) {
    <div [leafletLayer]="layer"></div>
  }
  <div [leafletLayer]="scanLayer()"></div>
</div>
