import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  OnDestroy,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusSwitchModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { TransferSpeedPipe } from '@trimble-gcs/ngx-common';
import { filter, map, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogData } from '../dialog/dialog.model';
import { DialogService } from '../dialog/dialog.service';
import { ImportFileListComponent } from './import-file-list/import-file-list.component';
import { ClearImportScans } from './import.actions';
import {
  ImportFile,
  ImportFileStatus,
  ImportScan,
  ImportScanStatus,
  ImportStatus,
} from './import.models';
import { ImportService } from './import.service';
import { ImportState } from './import.state';
import { LocalFilePickerComponent } from './local-file-picker/local-file-picker.component';
import { MAX_UPLOAD_FILES } from './upload.models';

export const importDialogDefaultConfig: MatDialogConfig = {
  disableClose: true,
  width: '70%',
  minWidth: '500px',
  maxWidth: '650px',
};

export type ImportDialogResult = {
  reloadScans: boolean;
};

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusTooltipModule,
    ModusIconModule,
    ModusButtonModule,
    ModusSwitchModule,
    MatDialogModule,
    LocalFilePickerComponent,
    ImportFileListComponent,
    TransferSpeedPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './import-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportDialogComponent implements OnDestroy {
  private selectedFiles = signal<ImportFile[]>([]);
  private importScansProgress = this.store.selectSignal(ImportState.importScans);

  importFiles = this.getImportFiles();
  remainingTime = this.getRemainingTime();

  importStatus = this.store.selectSignal(ImportState.status);
  importStarted = computed(() => this.importStatus() !== ImportStatus.NotStarted);
  disableImport = computed(() => this.selectedFiles().length === 0 || this.importStarted());

  maxFilesMessage = `Reached the maximum of ${MAX_UPLOAD_FILES} files allowed per upload.`;
  maxFilesReached = computed(() => this.selectedFiles().length === MAX_UPLOAD_FILES);

  private cancelImport = new Subject<void>();
  private cancelDialogRef: MatDialogRef<DialogComponent, boolean> | undefined;

  disableCancel = signal(false);
  cancelButtonText = computed(() =>
    this.importStatus() === ImportStatus.Error ? 'Close' : 'Cancel',
  );

  combineToSingleScanControl = new FormControl<boolean>(false, { nonNullable: true });

  private combineToSingleScan = toSignal(this.combineToSingleScanControl.valueChanges, {
    initialValue: this.combineToSingleScanControl.value,
  });

  constructor(
    private dialogRef: MatDialogRef<ImportDialogComponent, ImportDialogResult>,
    private dialogService: DialogService,
    private importService: ImportService,
    private store: Store,
  ) {
    this.createCombineInputEffect();
  }

  ngOnDestroy(): void {
    this.cancelDialogRef?.close();
  }

  addFiles(addFiles: ImportFile[]) {
    const files = [...this.selectedFiles(), ...addFiles];
    const limitedFiles = files.length > MAX_UPLOAD_FILES ? files.slice(0, MAX_UPLOAD_FILES) : files;

    this.selectedFiles.set(limitedFiles);
  }

  removeFiles(removeFiles: ImportFile[]) {
    const selectedFiles = this.selectedFiles();
    const filteredFiles = selectedFiles.filter((file) =>
      removeFiles.find((removeFile) => file.id !== removeFile.id),
    );
    this.selectedFiles.set(filteredFiles);
  }

  cancelClick() {
    const importStatus = this.importStatus();
    const showPrompt =
      (this.selectedFiles().length > 0 && importStatus === ImportStatus.NotStarted) ||
      importStatus === ImportStatus.Busy;

    const reloadScans = importStatus !== ImportStatus.NotStarted;

    if (!showPrompt) {
      this.store.dispatch(ClearImportScans);
      this.dialogRef.close({ reloadScans });
      return;
    }

    this.confirmCancel()
      .pipe(
        tap((cancel) => this.disableCancel.set(cancel)),
        filter((cancel) => cancel),
        switchMap(() => this.cancelAndDeleteIncompleteImports()),
        switchMap(() => this.store.dispatch(ClearImportScans)),
      )
      .subscribe(() => {
        this.dialogRef.close({ reloadScans });
      });
  }

  importClick() {
    const combineToSingleScan = this.combineToSingleScan();
    const importScans = this.getImportScans(this.selectedFiles(), combineToSingleScan);

    this.importService
      .import(importScans)
      .pipe(takeUntil(this.cancelImport))
      .subscribe(() => {
        if (this.importStatus() !== ImportStatus.Completed) return;

        this.store.dispatch(ClearImportScans);
        this.dialogRef.close({ reloadScans: true });
      });
  }

  private getImportFiles() {
    return computed(() => {
      const importFiles =
        this.importStatus() === ImportStatus.NotStarted
          ? this.selectedFiles()
          : this.importScansProgress().flatMap((importScan) => {
              return this.getImportScanFilesWithStatus(importScan);
            });

      return importFiles;
    });
  }

  private getImportScanFilesWithStatus(importScan: ImportScan) {
    const importFile = this.mapImportScanStatusToImportFile(importScan);
    return importScan.files.map((file) => (importFile?.id === file.id ? importFile : file));
  }

  private mapImportScanStatusToImportFile(importScan: ImportScan): ImportFile | undefined {
    /**
     * TODO: Remove this method once we display the ImportScan.
     * This method exists to map the ImportScan status onto an importFile.
     */
    switch (importScan.status) {
      case ImportScanStatus.CheckQuota:
      case ImportScanStatus.CreatePointcloud:
      case ImportScanStatus.CreateFiles:
        return {
          ...importScan.files[0],
          status: ImportFileStatus.Busy,
          statusMessage: importScan.status.toString(),
        };

      case ImportScanStatus.UploadFiles:
        const currentUpload =
          importScan.files.find(
            (file) =>
              file.status === ImportFileStatus.Pending || file.status === ImportFileStatus.Busy,
          ) ??
          importScan.files.toReversed().find((file) => file.status === ImportFileStatus.Completed);

        if (isNil(currentUpload)) return;

        return {
          ...currentUpload,
          status: ImportFileStatus.Busy,
          statusMessage: importScan.status.toString(),
        };

      case ImportScanStatus.Ingest:
        return {
          ...importScan.files.at(-1)!,
          status: ImportFileStatus.Busy,
          statusMessage: importScan.status.toString(),
        };

      case ImportScanStatus.Error:
        const errorFile =
          importScan.files.find((file) => file.status === ImportFileStatus.Error) ??
          importScan.files.find((file) => file.status === ImportFileStatus.Skipped) ??
          importScan.files.at(-1)!;

        return {
          ...errorFile,
          status: ImportFileStatus.Error,
          statusMessage: importScan.errorMessage,
        };

      default:
        return undefined;
    }
  }

  private getImportScans(selectedFiles: ImportFile[], combineToSingleScan: boolean): ImportScan[] {
    return combineToSingleScan
      ? [this.getImportScan(selectedFiles)]
      : selectedFiles.map((selectedFile) => this.getImportScan([selectedFile]));
  }

  private getImportScan(selectedFiles: ImportFile[]): ImportScan {
    const name =
      selectedFiles.length === 1
        ? selectedFiles[0].name
        : selectedFiles.length > 1
          ? `${selectedFiles[0].name} (${selectedFiles.length} combined files)`
          : '';
    return {
      id: uuidv4(),
      name,
      status: ImportScanStatus.Pending,
      files: selectedFiles.map((selectedFile) => ({
        ...selectedFile,
        status: ImportFileStatus.Pending,
      })),
    };
  }

  private confirmCancel() {
    const dialogTitle = this.importStatus() === ImportStatus.Busy ? 'Upload In Progress' : 'Upload';
    const dialogData = new DialogData(
      dialogTitle,
      'Are you sure you want to cancel this upload?',
      { text: 'Yes', color: 'danger' },
      { text: 'No', color: 'secondary' },
    );

    this.cancelDialogRef = this.dialogService.open(dialogData);

    return this.cancelDialogRef.afterClosed().pipe(map((confirmed) => confirmed ?? false));
  }

  private cancelAndDeleteIncompleteImports() {
    this.cancelImport.next();

    const cancelImports = this.importScansProgress().filter(
      (importScan) =>
        isDefined(importScan.scan) && importScan.status !== ImportScanStatus.Completed,
    );

    return cancelImports.length > 0 ? this.importService.cancelImport(cancelImports) : of(null);
  }

  private createCombineInputEffect() {
    effect(() => {
      if (this.importStatus() !== ImportStatus.NotStarted) {
        this.combineToSingleScanControl.disable();
        return;
      }

      const disableCombine = this.selectedFiles().length <= 1;
      if (disableCombine) {
        this.combineToSingleScanControl.setValue(false);
        this.combineToSingleScanControl.disable();
        return;
      }

      this.combineToSingleScanControl.enable();
    });
  }

  private getRemainingTime() {
    const transferSpeedPipe = new TransferSpeedPipe();
    let startedTime: number | null = null;

    return computed(() => {
      if (this.importStatus() !== ImportStatus.Busy) return;

      const bytesTransferred = this.importScansProgress()
        .flatMap((importScan) => importScan.files)
        .map((importFile) => importFile.fileUpload!.transferred ?? 0)
        .reduce((acc, cur) => acc + cur, 0);
      if (bytesTransferred === 0) return;

      if (isNil(startedTime)) {
        startedTime = this.importScansProgress()
          .flatMap((importScan) => importScan.files)
          .map((importFile) => importFile.fileUpload!.startTime?.getTime())
          .filter(isDefined)
          .reduce((acc: number | null, cur) => (isDefined(acc) && acc < cur ? acc : cur), null);
      }
      if (isNil(startedTime)) return;

      const secondsFromStart = (Date.now() - startedTime) / 1000;
      if (secondsFromStart === 0) return;

      const totalSize = this.importFiles().reduce((acc, cur) => acc + cur.size, 0);
      const bytesRemaning = totalSize - bytesTransferred;
      const transferRateBytesPerSecond = bytesTransferred / secondsFromStart;
      const secondsRemaining = bytesRemaning / transferRateBytesPerSecond;

      const totalMinutesRemaining = Math.ceil(secondsRemaining / 60);
      if (totalMinutesRemaining <= 1) return 'less than a minute';

      const hours = Math.floor(totalMinutesRemaining / 60);
      const minutes = Math.floor(totalMinutesRemaining % 60);

      const hoursFormatted = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
      const minutesFormatted = minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';
      const speedFormatted = transferSpeedPipe.transform(transferRateBytesPerSecond, 0);

      return `${hoursFormatted} ${minutesFormatted} - ${speedFormatted}`;
    });
  }
}
